import React from "react";
import Dropdown from "../../../../../../../dataInput/Dropdown";
import SecondaryActionButton from "../../../../../../../general/SecondaryActionButton";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";


function LabelWithExample({label, example}) {
  return <>{label} <span className="ml-2 float-right text-muted">{example}</span></>
}


const options = [
  {value: 'raw', label: <LabelWithExample label={'As it is'} example={'0.25'}/>},
  {value: 'percentage', label: <LabelWithExample label={'Percentage'} example={'25.00%'}/>},
  {value: 'percentage_number', label: <LabelWithExample label={'Percentage without sign'} example={'25.00'}/>},
  {value: 'comma_raw', label: <LabelWithExample label={'With decimal comma'} example={'0,25'}/>},
  {value: 'comma_percentage', label: <LabelWithExample label={'Percentage with decimal comma'} example={'25,00%'}/>},
  {
    value: 'comma_percentage_number',
    label: <LabelWithExample label={'Percentage with decimal comma, without sign'} example={'25,00'}/>
  },
]

export default function NumericFormatRule({numeric_format, onChange, onRemove}) {

  let currentValue = options.filter(({value}) => value === numeric_format);
  if (currentValue.length > 0) {
    currentValue = currentValue[0]
  } else {
    currentValue = null
  }

  return <div className={'d-flex'}>
    <Dropdown
      toggleButtonProps={{tag: SecondaryActionButton, size: 'sm'}}
      menuProps={{style: {width: '400px'}}}
      caret={false}
      placeholder={'Select a format'}
      options={options}
      value={currentValue}
      onChange={({value}) => {
        onChange({numeric_format: value})
      }}
    />
    <RemoveRuleIcon className={'ml-auto my-auto'} onClick={onRemove}/>
  </div>

}
