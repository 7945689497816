import React from "react";
import Dropdown from "../../../../../../../atoms/Dropdown";
import Button from "../../../../../../../atoms/Button";
import styles from "./ReplaceTextOptions.module.scss"
import {getClassNames} from "../../../../../../../classNameUtils";
import Checkbox from "../../../../../../../atoms/Checkbox";

export enum Locations {
  ALL = "all",
  WORD = "word",
  START = "start",
  WORD_BEGIN = "word_begin",
  END = "end",
  WORD_END = "word_end",
  FIRST = "first",
  LAST = "last"
}

const optionLabels = {
  [Locations.ALL]: "On all occurrences",
  [Locations.WORD]: "As full word",
  [Locations.START]: "From the beginning",
  [Locations.WORD_BEGIN]: "From the beginning of a word",
  [Locations.END]: "From the end",
  [Locations.WORD_END]: "From the end of a word",
  [Locations.FIRST]: "From the first occurrence",
  [Locations.LAST]: "From the last occurrence",
}

const getOption = (option: Locations, isSelected: boolean) => {

  let labelClasses = getClassNames([
    {className: styles.purpleText, condition: isSelected},
  ])

  let iconClasses = getClassNames([
    {className: "fas fa-remove-format", condition: true},
    {className: styles.purpleText, condition: isSelected},
  ])

  return {
    label: <span className={labelClasses}> {optionLabels[option]} </span>,
    icon: <i className={iconClasses}/>,
    value: option
  }
}

type ReplaceTextOptionsProps = {
  where: Locations,
  keep_text_case?: boolean,
  onChange: (option: { where: string | null | undefined } | { keep_text_case: boolean }) => void
}

export default function ReplaceTextOptions({where, keep_text_case, onChange}: ReplaceTextOptionsProps) {
  let options = []

  for (let location of Object.values(Locations)) {
    options.push(getOption(location, location === where))
  }
  if (keep_text_case !== undefined) {
    options.push({divider: true})
    options.push({
      label: <Checkbox
        onClick={(e) => {
          e.stopPropagation()
          onChange({keep_text_case: !keep_text_case})
        }}
        checked={keep_text_case}
        label={<span>Ignore text-case rules</span>}
      />
    })
  }

  return <Dropdown
    className={"ml-3 mr-4"}
    ToggleTag={(props) =>
      <Button style={{height: "2rem", width: "2rem"}}
              className={"justify-content-center"}
              onClick={props.toggleDropdowns}>
        <i className="fas fa-sliders-h mr-0"/>
      </Button>}
    options={options}
    onSelectOption={(option) => onChange({where: option.value})}
    currentValue={null}/>
}
