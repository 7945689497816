import Dropdown from "../../../../../../../dataInput/Dropdown";
import SecondaryActionButton from "../../../../../../../general/SecondaryActionButton";
import React from "react";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";
import styles from "./TextCaseRule.module.scss";

const UPPER = <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M6.68487 0.535999H1.28087C0.790874 0.535999 0.412874 0.928 0.412874 1.404C0.412874 1.894 0.790874 2.272 1.28087 2.272H3.03087V9.048C3.03087 9.58 3.45087 10 3.98287 10C4.50087 10 4.93487 9.58 4.93487 9.048V2.272H6.68487C7.16087 2.272 7.55287 1.894 7.55287 1.404C7.55287 0.928 7.16087 0.535999 6.68487 0.535999ZM14.7134 0.535999H9.30944C8.81944 0.535999 8.44144 0.928 8.44144 1.404C8.44144 1.894 8.81944 2.272 9.30944 2.272H11.0594V9.048C11.0594 9.58 11.4794 10 12.0114 10C12.5294 10 12.9634 9.58 12.9634 9.048V2.272H14.7134C15.1894 2.272 15.5814 1.894 15.5814 1.404C15.5814 0.928 15.1894 0.535999 14.7134 0.535999Z"
    fill="#333333"/>
</svg>

const LOWER = <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M6.49206 7.908C6.38006 7.488 5.93206 7.236 5.51206 7.362C4.98006 7.516 4.61606 7.432 4.40606 7.18C4.23806 6.97 4.16806 6.662 4.16806 6.256V4.016H5.42806C5.89006 4.016 6.26806 3.652 6.26806 3.176C6.26806 2.714 5.89006 2.336 5.42806 2.336H4.16806V0.964C4.16806 0.46 3.74806 0.0399994 3.24406 0.0399994C2.72606 0.0399994 2.32006 0.46 2.32006 0.964V2.336H1.64806C1.17206 2.336 0.808062 2.714 0.808062 3.176C0.808062 3.652 1.17206 4.016 1.64806 4.016H2.32006V5.794C2.32006 6.578 2.30606 7.222 2.67006 7.894C3.32806 9.084 4.72806 9.336 5.94606 8.888C6.36606 8.734 6.61806 8.328 6.49206 7.908ZM13.1534 7.908C13.0414 7.488 12.5934 7.236 12.1734 7.362C11.6414 7.516 11.2774 7.432 11.0674 7.18C10.8994 6.97 10.8294 6.662 10.8294 6.256V4.016H12.0894C12.5514 4.016 12.9294 3.652 12.9294 3.176C12.9294 2.714 12.5514 2.336 12.0894 2.336H10.8294V0.964C10.8294 0.46 10.4094 0.0399994 9.90544 0.0399994C9.38744 0.0399994 8.98144 0.46 8.98144 0.964V2.336H8.30944C7.83344 2.336 7.46944 2.714 7.46944 3.176C7.46944 3.652 7.83344 4.016 8.30944 4.016H8.98144V5.794C8.98144 6.578 8.96744 7.222 9.33144 7.894C9.98944 9.084 11.3894 9.336 12.6074 8.888C13.0274 8.734 13.2794 8.328 13.1534 7.908Z"
    fill="#333333"/>
</svg>

const TITLE = <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M6.36847 0.535999H0.964468C0.474468 0.535999 0.0964678 0.928 0.0964678 1.404C0.0964678 1.894 0.474468 2.272 0.964468 2.272H2.71447V9.048C2.71447 9.58 3.13447 10 3.66647 10C4.18447 10 4.61847 9.58 4.61847 9.048V2.272H6.36847C6.84447 2.272 7.23647 1.894 7.23647 1.404C7.23647 0.928 6.84447 0.535999 6.36847 0.535999ZM13.837 8.908C13.725 8.488 13.277 8.236 12.857 8.362C12.325 8.516 11.961 8.432 11.751 8.18C11.583 7.97 11.513 7.662 11.513 7.256V5.016H12.773C13.235 5.016 13.613 4.652 13.613 4.176C13.613 3.714 13.235 3.336 12.773 3.336H11.513V1.964C11.513 1.46 11.093 1.04 10.589 1.04C10.071 1.04 9.66503 1.46 9.66503 1.964V3.336H8.99303C8.51703 3.336 8.15303 3.714 8.15303 4.176C8.15303 4.652 8.51703 5.016 8.99303 5.016H9.66503V6.794C9.66503 7.578 9.65103 8.222 10.015 8.894C10.673 10.084 12.073 10.336 13.291 9.888C13.711 9.734 13.963 9.328 13.837 8.908Z"
    fill="#333333"/>
</svg>


const caseNames = {
  lower: 'Lowercase',
  upper: 'Uppercase',
  title: 'Title Case'
}


export function TextCaseRule({transform, onChange, onRemove}) {
  let displayValue = null;
  if (transform) {
    displayValue = {label: caseNames[transform]}
  }
  return <div className={'d-flex align-items-center'}>
    <i className={`fas fa-font-case ${styles.brandRuleIcon}`}/>
    <span className={"mr-1"}>The text case for all data points in this column is set to</span>
    <Dropdown
      className={'m-1'}
      value={displayValue}
      toggleButtonProps={{tag: SecondaryActionButton, size: 'sm'}}
      placeholder={'Select text case rule'}
      onChange={chosen => onChange({transform: chosen.value})}
      options={[
        {
          value: 'upper',
          label: <div className={'d-inline-flex'}>
            <div className={'d-flex align-items-center mr-2'}>{UPPER}</div>
            {caseNames.upper}</div>,
        },
        {
          value: 'lower',
          label: <div className={'d-inline-flex'}>
            <div className={'d-flex align-items-center mr-2'}>{LOWER}</div>
            {caseNames.lower}</div>,
        },
        {
          value: 'title',
          label: <div className={'d-inline-flex'}>
            <div className={'d-flex align-items-center mr-2'}>{TITLE}</div>
            {caseNames.title}</div>,
        },
      ]}
    />
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>
}
