import React, {useState} from "react";
import {ProductRowLayout} from "../layout/OrderProductLayout";
import DetailedProductModal from "../../products/detailed/DetailedProductModal";
import SimpleTableRow, {SimpleTableCell} from "../../../molecules/tables/SimpleTableRow";
import styles from './ProductRow.module.scss';
import Pill from "../../../atoms/Pill";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {ProductType} from "./productViews/commons/ProductList";
import addClassNames from "../../../classNameUtils";

type ProductRowProps = {
  product: ProductType,
  showAggregateColorAndSizesCount: boolean,
  environment: RelayModernEnvironment,
  hasMissingDataStatus: boolean
}

export default function ProductRow({product, showAggregateColorAndSizesCount, environment, hasMissingDataStatus}: ProductRowProps) {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const missingData = product.allMissingData.map(data => data.display)

  return <>
    <SimpleTableRow className={addClassNames([
      {className: styles.productRow, condition: true},
      {className: styles.productRowMissingValues, condition: hasMissingDataStatus && !!missingData.length},
    ])}>
      <SimpleTableCell className={`${styles.productCell} justify-content-center px-4`}>
        <ProductRowLayout.Image image={product.image}/>
      </SimpleTableCell>
      <SimpleTableCell width={18} className={`${styles.productCell} pl-0`}>
        <p className={styles.productText} data-testid={"order-text-style-name"}>{product.styleName}</p>
        {hasMissingDataStatus && !!missingData.length &&
          <ProductRowLayout.MissingDataInfo missingData={product.allMissingData.map(data => data.display)}/>}
      </SimpleTableCell>
      <SimpleTableCell width={14} className={styles.productCell}>
        <p className={styles.productText} data-testid={"order-text-style-number"}>{product.styleNumber || "-"}</p>
      </SimpleTableCell>
      <SimpleTableCell width={14} className={styles.productCell}>
        <ProductRowLayout.VariantsDropdown variants={product.variants} hasMissingData={hasMissingDataStatus && !!missingData.length}/>
      </SimpleTableCell>
      <SimpleTableCell width={14} className={styles.productCell}>
        <p className={styles.productText}>{product.quantity || "-"}</p>
      </SimpleTableCell>
      <SimpleTableCell width={14} className={styles.productCell}>
        <p className={styles.productText}>{product.price ? `${product.price.amount} ${product.price.currency}` : "-"}</p>
      </SimpleTableCell>
      <SimpleTableCell width={8} className={styles.productCell}>
        {/*//Future column*/}
      </SimpleTableCell>
      {/* //Hidden for now*/}
      {/*<SimpleTableCell className={`${styles.productCell} justify-content-center`}>*/}
      {/*  <Pill onClick={toggle}>See details</Pill>*/}
      {/*</SimpleTableCell>*/}
    </SimpleTableRow>
    {/* //Hidden for now*/}
    {/*<DetailedProductModal isOpen={isOpen} toggle={toggle} productId={product.id} environment={environment}/>*/}
  </>
}
