import Dropdown, {DropdownMenu, SelectableOption} from "../../atoms/Dropdown";
import addClassNames from "../../classNameUtils";
import Pill from "../../atoms/Pill";
import styles from "./ColumnsSelectorDropdown.module.scss";
import React, {useEffect} from "react";
import {useWindowDimensions} from "../../commons/hooks";
import Tooltip from "../../atoms/Tooltip";

export const availableColumns = {
  ORDER_AND_NUMBER: "ORDER_AND_NUMBER",
  LOCATION: "LOCATION",
  DATE_ADDED: "DATE_ADDED",
  CUSTOMER_REFERENCE: "CUSTOMER_REFERENCE",
  DELIVERY_PERIOD: "DELIVERY_PERIOD",
  COLLECTION: "COLLECTION",
  COMMENT: "COMMENT",
  STATUS: "STATUS",
  STATUS_DETAILS: "STATUS_DETAILS"
}

const defaultColumns = [availableColumns.ORDER_AND_NUMBER, availableColumns.STATUS]

type DisabledColumnTooltipProps = {
  isDisabled: boolean,
  position?: "top" | "bottom",
  children: JSX.Element
}

function DisabledColumnTooltip({isDisabled, position = "bottom", children}: DisabledColumnTooltipProps) {
  if (!isDisabled) {
    return children
  }

  if (position === "top") {
    return <Tooltip text={"Please disable some \nof the other options."}
                    placement={'top'}
                    tipStyle={{top: '-4.9rem', left: "0.1rem", width: 'max-content'}}>
      {children}
    </Tooltip>
  }

  return <Tooltip text={"Please disable some \nof the other options."}
                  placement={'top'}
                  tipStyle={{top: '-1.25rem', left: "0.1rem", width: 'max-content'}}>
    {children}
  </Tooltip>
}

type ColumnsSelectorDropdownProps = {
  activeColumns: string[],
  setActiveColumns: (val: string[]) => void
}

export default function ColumnsSelectorDropdown({activeColumns, setActiveColumns}: ColumnsSelectorDropdownProps) {
  const maxActiveColumns = useWindowDimensions().width > 1700 ? 5 : 4

  const isOptionSelected = (option: string) => {
    return activeColumns.includes(option)
  }

  const isOptionDisabled = (option: string) => {
    if(defaultColumns.includes(option)) {
      return true;
    }

    if (!isOptionSelected(option) && activeColumns.length >= maxActiveColumns) {
      return true;
    }

    return false;
  }

  const onSelectOption = (option: string) => {
    const newActiveColumnOptions = [...activeColumns]
    if(isOptionSelected(option)) {
      newActiveColumnOptions.splice(newActiveColumnOptions.indexOf(option), 1);
    } else if(activeColumns.length < maxActiveColumns) {
      newActiveColumnOptions.push(option);
    }

    localStorage.setItem("activePageColumns", JSON.stringify(newActiveColumnOptions))
    setActiveColumns(newActiveColumnOptions)
  }

  useEffect(() => {
    // Remove Date Added if the screen is too narrow
    if(activeColumns.length > maxActiveColumns && isOptionSelected(availableColumns.DATE_ADDED)) {
      onSelectOption(availableColumns.DATE_ADDED)
    }
  }, [maxActiveColumns]);

  const columnOptions: SelectableOption[] = [
    {
      value: availableColumns.ORDER_AND_NUMBER,
      label: <label className={styles.selectorOption}>Order & Number</label>,
      icon: <i className={`fa-fw fa-solid fa-square-check ${styles.selectorOptionCheckboxDisabled}`}/>,
      disabled: isOptionDisabled(availableColumns.ORDER_AND_NUMBER),
    },
    {
      value: availableColumns.LOCATION,
      label: <label className={styles.selectorOption}>Location</label>,
      icon: <DisabledColumnTooltip isDisabled={isOptionDisabled(availableColumns.LOCATION)}>
        <i className={addClassNames([
          {className: `fa-fw ${styles.selectorOptionCheckbox}`, condition: true},
          {
            className: `fa-solid fa-square-check ${styles.selectorOptionCheckboxChecked}`,
            condition: isOptionSelected(availableColumns.LOCATION)
          },
          {className: "fa-thin fa-square", condition: !isOptionSelected(availableColumns.LOCATION)}
        ])}/>
      </DisabledColumnTooltip>,
      disabled: isOptionDisabled(availableColumns.LOCATION)
    },
    {
      value: availableColumns.DATE_ADDED,
      label: <label className={styles.selectorOption}>Date added</label>,
      icon: <DisabledColumnTooltip isDisabled={isOptionDisabled(availableColumns.DATE_ADDED)}>
        <i className={addClassNames([
          {className: `fa-fw ${styles.selectorOptionCheckbox}`, condition: true},
          {
            className: `fa-solid fa-square-check ${styles.selectorOptionCheckboxChecked}`,
            condition: isOptionSelected(availableColumns.DATE_ADDED)
          },
          {className: "fa-thin fa-square", condition: !isOptionSelected(availableColumns.DATE_ADDED)}
        ])}/>
      </DisabledColumnTooltip>,
      disabled: isOptionDisabled(availableColumns.DATE_ADDED)
    },
    {
      value: availableColumns.CUSTOMER_REFERENCE,
      label: <label className={styles.selectorOption}>Customer reference</label>,
      icon: <DisabledColumnTooltip isDisabled={isOptionDisabled(availableColumns.CUSTOMER_REFERENCE)}>
        <i className={addClassNames([
          {className: `fa-fw ${styles.selectorOptionCheckbox}`, condition: true},
          {
            className: `fa-solid fa-square-check ${styles.selectorOptionCheckboxChecked}`,
            condition: isOptionSelected(availableColumns.CUSTOMER_REFERENCE)
          },
          {className: "fa-thin fa-square", condition: !isOptionSelected(availableColumns.CUSTOMER_REFERENCE)}
        ])}/>
      </DisabledColumnTooltip>,
      disabled: isOptionDisabled(availableColumns.CUSTOMER_REFERENCE)
    },
    {
      value: availableColumns.DELIVERY_PERIOD,
      label: <label className={styles.selectorOption}>Delivery period</label>,
      icon: <DisabledColumnTooltip isDisabled={isOptionDisabled(availableColumns.DELIVERY_PERIOD)}>
        <i className={addClassNames([
          {className: `fa-fw ${styles.selectorOptionCheckbox}`, condition: true},
          {
            className: `fa-solid fa-square-check ${styles.selectorOptionCheckboxChecked}`,
            condition: isOptionSelected(availableColumns.DELIVERY_PERIOD)
          },
          {className: "fa-thin fa-square", condition: !isOptionSelected(availableColumns.DELIVERY_PERIOD)}
        ])}/>
      </DisabledColumnTooltip>,
      disabled: isOptionDisabled(availableColumns.DELIVERY_PERIOD)
    },
    {
      value: availableColumns.COMMENT,
      label: <label className={styles.selectorOption}>Comment</label>,
      icon: <DisabledColumnTooltip isDisabled={isOptionDisabled(availableColumns.COMMENT)}>
        <i className={addClassNames([
          {className: `fa-fw ${styles.selectorOptionCheckbox}`, condition: true},
          {
            className: `fa-solid fa-square-check ${styles.selectorOptionCheckboxChecked}`,
            condition: isOptionSelected(availableColumns.COMMENT)
          },
          {className: "fa-thin fa-square", condition: !isOptionSelected(availableColumns.COMMENT)}
        ])}/>
      </DisabledColumnTooltip>,
      disabled: isOptionDisabled(availableColumns.COMMENT)
    },
    {
      value: availableColumns.STATUS,
      label: <label className={styles.selectorOption}>Status</label>,
      icon: <i className={`fa-fw fa-solid fa-square-check ${styles.selectorOptionCheckboxDisabled}`}/>,
      disabled: isOptionDisabled(availableColumns.STATUS),
    },
    {
      value: availableColumns.STATUS_DETAILS,
      label: <label className={styles.selectorOption}>Status details</label>,
      icon: <DisabledColumnTooltip isDisabled={isOptionDisabled(availableColumns.STATUS_DETAILS)} position={"top"}>
        <i className={addClassNames([
          {className: `fa-fw ${styles.selectorOptionCheckbox}`, condition: true},
          {
            className: `fa-solid fa-square-check ${styles.selectorOptionCheckboxChecked}`,
            condition: isOptionSelected(availableColumns.STATUS_DETAILS)
          },
          {className: "fa-thin fa-square", condition: !isOptionSelected(availableColumns.STATUS_DETAILS)}
        ])}/>
      </DisabledColumnTooltip>,
      disabled: isOptionDisabled(availableColumns.STATUS_DETAILS)
    },
  ]

  return <Dropdown currentValue={null}
                   options={columnOptions}
                   menuWidth={"10.25rem"}
                   isSearchable={false}
                   ToggleTag={props => (
                     <Pill onClick={props.toggleDropdowns} color={"transparent"}>
                       <i className={"fa-regular fa-eye-slash"}/>
                       <label className={styles.columnsSelectorTag}>Show/Hide columns</label>
                     </Pill>
                   )}
                   MenuTag={(props) => <DropdownMenu menuItemClassName={styles.dropdown} {...props}/>}
                   onSelectOption={op => op.value && onSelectOption(op.value)}
  />
}
