import {capitalize} from "../../../stringUtils";
import {DDTConfigType, ProductFieldType} from "./types";
import {Header, Option} from "../../../atoms/Dropdown";

function same<T>(x: T) {
  return x;
}

export class ProductFieldsProvider {
  rawProductFields: ProductFieldType[];
  _cachedOptions: Option[] | null;

  constructor(rawProductFields: ProductFieldType[]) {
    this.rawProductFields = rawProductFields;
    this._cachedOptions = null;
  }

  getByPath<A = ProductFieldType> (path: string | null, config?: { formatter: (item: ProductFieldType) => A }): (A | null) {
    if (!path) {
      return null;
    }

    const formatter = (config?.formatter || same) as (item: ProductFieldType) => A;
    const found = this.rawProductFields.filter(r => r.path?.toLowerCase() === path.replace(/ /g, '-').toLowerCase())
    if (found.length === 0) {
      return null
    }

    return formatter(found[0]);
  }

  getFields = () => {
    return this.rawProductFields;
  }

  partitionByCategory = (fields: ProductFieldType[]) => {
    const partitions: {[key: string]: ProductFieldType[]} = {};
    fields.forEach(f => {
      let cat: string;
      if (!f.hasOwnProperty('category')) {
        cat = 'product';
      } else {
        cat = f.category || "null";
      }

      if (!partitions.hasOwnProperty(cat)) {
        partitions[cat] = [];
      }
      partitions[cat].push(f)
    })

    return partitions;
  }

  getDropdownOptions = () => {
    if (this._cachedOptions) {
      return this._cachedOptions;
    }

    const byCategoryPartition = this.partitionByCategory(this.rawProductFields);
    let options: (ProductFieldType | Header)[] = []
    options = options.concat(byCategoryPartition['product'])
    for (let orderConfirmCategory of Object.keys(byCategoryPartition).filter(k => k.startsWith('orderconfirmation_'))) {
      options.push({header: true, label: 'Order confirmation > ' + capitalize(orderConfirmCategory.substring(18))})
      options = options.concat(byCategoryPartition[orderConfirmCategory]);
    }

    const dropdownOptions: Option[] = options
      .filter((o): o is ProductFieldType => o.hasOwnProperty('path') && o.hasOwnProperty('name'))
      .map(({path, name}) => {
        return {value: path, label: name};
      })

    if (Object.keys(byCategoryPartition).length > 1) {
      dropdownOptions.unshift({header: true, label: 'Product'});
    }

    this._cachedOptions = dropdownOptions;
    return dropdownOptions;
  }

  getMultiMenuOptions = () => {
    const categories = this.partitionByCategory(this.rawProductFields);

    let finalOptions: (Option & { type?: string[] | null })[] = [];
    for (let catKey of Object.keys(categories)) {
      if (catKey === 'null') {
        continue
      }
      finalOptions.push({
        label: this.makeCategoryNameUserFriendly(catKey),
        options: categories[catKey].map(v => ({label: v.name, value: v.path, type: v.type})),
      })
    }

    const nullCategory = categories['null']
    if (nullCategory) {
      finalOptions = finalOptions.concat(nullCategory.map(v => ({
        label: v.name,
        value: v.path,
        type: v.type,
      })))
    }

    return finalOptions
  }

  makeCategoryNameUserFriendly = (catName: string) => {
    if (catName.startsWith('orderconfirmation_')) {
      return 'Order confirmation: ' + capitalize(catName.split('_').pop() as string)
    } else {
      return capitalize(catName);
    }
  }
}

export function getEmptyConfigClone(config: DDTConfigType): DDTConfigType {
  return config.map(configRow => ({
    outputField: configRow.outputField,
    config: {
      required: false,
      rules: [],
      tokens: []
    }
  }))
}

export function hasType(tokenTypes: string[] | string | null, type: string) {
  if(Array.isArray(tokenTypes)) {
    return tokenTypes?.includes(type);
  } else if(typeof tokenTypes === 'string') {
    return tokenTypes === type;
  }

  return false;
}
