import React from "react";
import Input from "reactstrap/lib/Input";
import Row from "reactstrap/lib/Row";
import Dropdown from "../../../../../atoms/Dropdown";
import {useDDTMappingConfigFormContext} from "../../DDTMappingConfigFormContext";
import {GlossaryEntry} from "../../types";
import UniqueValueSingleSelectDropdown from "../dropdowns/UniqueValueSingleSelectDropdown";
import RemoveRuleIcon from "../fieldRules/RemoveRuleIcon";
import styles from "./GlossaryRow.module.scss";
import {capitalize} from "../../../../../stringUtils";

function isUniqueValue(type: string []) {
  return type.filter(t => t.startsWith('$$')).length > 0;
}

type GlossaryRowProps = {
  index: number,
  type: string [],
  isOrientation: boolean,
  glossaryValues: GlossaryEntry[],
  orientationOptions: {value: string, label: string} []
  onGlossaryValuesChange: (index: number, value: GlossaryEntry[]) => void,
  onRemove: (index: number) => void
}

export default function GlossaryRow({
                                      index,
                                      isOrientation,
                                      type,
                                      orientationOptions,
                                      glossaryValues,
                                      onGlossaryValuesChange,
                                      onRemove
                                    }: GlossaryRowProps) {
  let {getUniqueValueDetailsByUniqueValue} = useDDTMappingConfigFormContext()

  let value
  if (glossaryValues[index].display) {
    value = glossaryValues[index].display
  } else if (getUniqueValueDetailsByUniqueValue(glossaryValues[index].raw)) {
    value = getUniqueValueDetailsByUniqueValue(glossaryValues[index].raw)?.value
  } else if (isOrientation) {
    value = capitalize(glossaryValues[index].raw)
  }

  return <Row key={index} className={'d-flex align-items-center pt-4'} noGutters>
    <div className={'d-flex align-items-center'}>
      <div
        className={"d-flex justify-content-center align-items-center rounded-circle mt-1 mr-1 " + styles.iconBackground}>
        <i className="fas fa-exchange-alt text-white font-size" style={{fontSize: "0.75rem"}}/>
      </div>
    </div>
    <span className={"px-2"}>Instead of</span>
    <div className={'d-flex align-items-center mx-1'}>
      {isOrientation && <Dropdown
        currentValue={{label: value as string || "Chose Orientation..."}}
        options={orientationOptions}
        onSelectOption={(option) => {
          if (option.value && option.label) {
            onGlossaryValuesChange(index, glossaryValues.map((val, i) => {
              if (i === index) {
                return {...val, raw: option.value as string, display: option.label as string,};
              }
              return val;
            }))
          }
        }
        }/>}
      {isUniqueValue(type) &&
        <UniqueValueSingleSelectDropdown type={type}
                                         value={value}
                                         presentValues={glossaryValues.map(value => value.raw)}
                                         onOptionChange={({value, label}) => {
                                           if (value && label) {
                                             onGlossaryValuesChange(index, glossaryValues.map((val, i) => {
                                               if (i === index) {
                                                 return {...val, raw: value, display: label as string,};
                                               }
                                               return val;
                                             }))
                                           }
                                         }}/>}
      {!isUniqueValue(type) && !isOrientation && <Input
        placeholder={"Value"}
        value={glossaryValues[index].raw}
        style={{borderRadius: "0.5rem"}}
        onChange={e => {
          onGlossaryValuesChange(index, glossaryValues.map((val, i) => {
            if (i === index) {
              return {...val, raw: e.target.value};
            }
            return val;
          }))
        }}
      />}
    </div>
    <div className={'d-flex align-items-center mx-2'}>
      display
    </div>
    <div className={'d-flex align-items-center ml-1'}>
      <Input value={glossaryValues[index].value}
             placeholder={"Desired appearance"}
             style={{borderRadius: "0.5rem"}}
             onChange={e => {
               onGlossaryValuesChange(index, glossaryValues.map((val, i) => {
                 if (i === index) {
                   return {...val, value: e.target.value};
                 }
                 return val;
               }))
             }}/>
    </div>
    <div className={'d-flex align-items-center ml-auto'}>
      <RemoveRuleIcon
        onClick={() => onRemove(index)}
        className={'ml-auto'}/>
    </div>
  </Row>
}
