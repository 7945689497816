import React from "react";
import {GeneralRulesTypes, RemoveSectionRuleType} from "../../../../types";
import {ProductFieldsProvider} from "../../../../utilities";
import SectionPathDropdown from "../commons/SectionPathDropdown";
import RuleExtrasDropdown from "../commons/RuleExtrasDropdown";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";


type RemoveSectionRuleProps = {
  fieldDisplay: string,
  onChange: (value: RemoveSectionRuleType) => void,
  productFieldsProvider: ProductFieldsProvider,
  onRemove: () => void,
} & RemoveSectionRuleType

export default function RemoveSectionRule({
                                            section_path,
                                            fieldDisplay,
                                            onChange,
                                            productFieldsProvider,
                                            onRemove,
                                            exceptions
                                          }: RemoveSectionRuleProps) {
  let currentValue = productFieldsProvider.getByPath(section_path)
  return <div className={'d-flex mt-4 ml-4'}>
    <SectionPathDropdown
      currentValue={currentValue}
      onChange={onChange}
      productFieldsProvider={productFieldsProvider}
      fieldDisplay={fieldDisplay}
      sectionPath={section_path}
    />
    <RuleExtrasDropdown onChange={onChange}
                        ruleData={{
                          type: GeneralRulesTypes.RemoveSectionRule,
                          section_path,
                          exceptions
                        }}/>
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>

}
