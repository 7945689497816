import React from "react";
import Dropdown from "../../../../../../../dataInput/Dropdown";
import SecondaryActionButton from "../../../../../../../general/SecondaryActionButton";


export default function SectionPathDropdown({currentValue, sectionPath, fieldDisplay, onChange, productFieldsProvider,}) {
  return <Dropdown
    className={'m-1'}
    options={productFieldsProvider.getFields().filter(x => {
      return x.name !== fieldDisplay
    }).map(({path, name}) => {
      return {value: path, label: name};
    })}
    toggleButtonProps={{tag: SecondaryActionButton, size: 'sm'}}
    placeholder={'Select a section to remove'}
    value={sectionPath ? {value: currentValue.path, label: currentValue.name} : null}
    currentValueRenderer={(v) => {
      return 'Removed section: ' + v.label;
    }}
    onChange={newVal => {
      onChange({section_path: newVal.value});
    }}
  />
}
