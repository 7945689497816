import {noop} from "../../commons/misc";
import {cloneObject} from "../../objectUtils";
import {capitalize} from "../../stringUtils";
import RULES from "./DDTMappingConfigV2Form/components/rules";
import {Locations} from "./DDTMappingConfigV2Form/components/token/rules/commons/ReplaceTextOptions";
import {FieldRules} from "./DDTMappingConfigV2Form/fieldRulesUtils";
import {hasType} from "./DDTMappingConfigV2Form/utilities";
import {
  DDTConfigType,
  DdtSchemaType,
  FieldRuleType, GeneralRulesTypes,
  GeneralTokenRule, isGenerateDescriptionTokenRuleType,
  isProductPathToken,
  isReduceLengthFieldRule,
  isReplaceContentFieldRule,
  ProductFieldType,
  ProductPathTokenType,
  ReplaceContentFieldRuleReplacementsType,
  RowConfigType,
  TokenGlossaryType,
  TokenType
} from "./DDTMappingConfigV2Form/types";
import {
  BackendProductPathTokenType,
  BackendRowConfigType,
  BackendTokenType,
  isBackendProductPathToken
} from "./backendAdapterTypes";

function ensureArray<T>(arr: T[]) {
  if (!arr) {
    return []
  }
  if (Array.isArray(arr)) {
    return arr;
  }
  throw Error('can not be converted to arr: ' + arr)
}

/**
 * When we do operations that change some token indexes, we need to go through
 * the all places where these tokens are referenced by the index and we need
 * to change those references to the new positions of the tokens.
 *
 * Example of such operations: deleting a token (causes all the tokens after it to shift to left)
 *
 * Note: fieldConfig must be mutable and the changes will be made in-place
 *
 // * @param fieldConfig: the field config object with {token, rules, brandRules} keys
 // * @param tokenAdjustments: An object where the key is the old index, and the value is the new index.
 *                          Basically a index translation dict.
 */

export function adjustTokenIndexReferences<T extends RowConfigType | BackendRowConfigType>(fieldConfig: T, tokenAdjustments: { [key: number]: (number | null) }) {
  const applyAdjustmentsToArray = (i: number | null) => {
    if (i && tokenAdjustments.hasOwnProperty(i)) {
      return tokenAdjustments[i];
    } else {
      return i;  // no change
    }
  }

  const applyAdjustmentsToIndex = <T extends {index: number}>(obj: T) => {
    let i = obj.index;
    if (tokenAdjustments.hasOwnProperty(i)) {
      return {...obj, index: tokenAdjustments[i]}
    } else {
      return obj;  // no change
    }
  }

  const remapWithoutExtraKeys = (replacement: ReplaceContentFieldRuleReplacementsType) => {
    if (replacement.hasOwnProperty('isSeparator') || replacement.hasOwnProperty('isRemove')) {
      return {index: replacement.index, value: replacement.value}
    } else {
      return replacement; // no change
    }
  }

  fieldConfig = cloneObject(fieldConfig);
  // Case at the moment where we reference token indexes directly
  for (let rule of ensureArray(fieldConfig.rules)) {
    //  is for the field rule "reduce_length" -> action "reduce_token_length" / "alter_token"
    if (isReduceLengthFieldRule(rule)) {
      for (let action of ensureArray(rule.actions)) {
        if (action.type === 'reduce_token_length') {
          action.token_indexes = action.token_indexes.map(applyAdjustmentsToArray)
        } else if (action.type === 'alter_token') {
          action.transforms = action.transforms.map(applyAdjustmentsToIndex)
        }
      }
    } else if (isReplaceContentFieldRule(rule)) {
      rule.replacements = rule.replacements.map(remapWithoutExtraKeys)
      rule.replacements = rule.replacements.map(r => {
        if (r?.index != null) {
          return applyAdjustmentsToIndex({ ...r, index: r.index });
        } else {
          return r;
        }
      })
    } else if (rule.type === FieldRules.REMOVE_CONTENT as string) {
      // Older rule
      //@ts-ignore
      rule.removals = rule.removals.map(applyAdjustmentsToIndex);
    }
  }

  return fieldConfig;
}

function getEmptyConfigElement(field: string) {
  return {
    outputField: field,
    config: {
      required: false,
      rules: [],
      tokens: [],
    }
  }
}

export function buildEmptyConfigFromDdtSchema(ddtSchema: DdtSchemaType): DDTConfigType {
  if (Array.isArray(ddtSchema)) {
    return ddtSchema.map((k) => getEmptyConfigElement(k))
  }

  return Object.keys(ddtSchema.properties).map((k) => getEmptyConfigElement(k))
}

export function _sanitizeRuleForFrontend(rule: {[key: string]: unknown}): GeneralTokenRule {
  if (rule.hasOwnProperty('transform')) {
    return {...rule, type: RULES.TEXT_CASE_RULE.id} as GeneralTokenRule;
  } else if (rule.hasOwnProperty('pattern')) {
    return {...rule, type: RULES.REMOVE_CHARACTERS_RULE.id} as GeneralTokenRule;
  } else if (rule.hasOwnProperty('section_path')) {
    return {...rule, type: RULES.REMOVE_SECTION_RULE.id} as GeneralTokenRule;
  } else if (rule.hasOwnProperty('default')) {
    return {default_value: rule.default, type: RULES.DEFAULT_RULE.id} as GeneralTokenRule
  } else if (rule.hasOwnProperty('replace_with') || rule.hasOwnProperty('replace_target')) {
    return {
      replace_with: rule.replace_with,
      replace_target: rule.replace_target,
      type: RULES.REPLACE_CHARACTERS_RULE.id,
      where: rule.where ? rule.where : Locations.ALL,
      keep_text_case: rule.keep_text_case ? rule.keep_text_case : false,
      exceptions: rule.exceptions ? rule.exceptions : []
    } as GeneralTokenRule
  } else if (rule.hasOwnProperty('numeric_format')) {
    return {numeric_format: rule.numeric_format, type: RULES.NUMERIC_FORMAT_RULE.id} as GeneralTokenRule
  } else if (rule.hasOwnProperty('paths')) {
    return {...rule, type: RULES.FIRST_AVAILABLE_RULE.id} as GeneralTokenRule
  } else if (rule.hasOwnProperty('before') && rule.hasOwnProperty('after') && rule.hasOwnProperty('ignore_if_none')) {
    return {...rule, type: RULES.CUSTOM_TEXT_RULE.id} as GeneralTokenRule
  } else if (rule.hasOwnProperty('values')) {
    return {...rule, type: RULES.CUSTOMIZE_VALUES_RULE.id} as GeneralTokenRule
  } else if (rule.hasOwnProperty('conditions') && rule.hasOwnProperty('replacements')) {
    return {...rule, type: RULES.CONDITIONAL_RULE.id} as GeneralTokenRule
  } else if (!rule.hasOwnProperty('type') && isGenerateDescriptionTokenRuleType(rule as GeneralTokenRule)) {
    return {...rule, type: GeneralRulesTypes.GenerateDescriptionTokenRule} as GeneralTokenRule
  }

  return rule as GeneralTokenRule;
}

export function _transformGlossaryForFrontend(backendGlossary: {[key: string]: string}) {
  if (!backendGlossary) {
    return []
  }
  if (Array.isArray(backendGlossary)) {
    // already a frontend-compatible list, return it immediately
    return backendGlossary;
  }
  let glossary = [];
  for (let k of Object.keys(backendGlossary)) {
    let v = backendGlossary[k];
    if (k.startsWith('$$')) {
      // is a unique value
      glossary.push({
        type: 'uniqueValue',
        raw: k,
        value: v,
      });
    } else {
      glossary.push({type: 'raw', raw: k, value: v});
    }
  }
  return glossary;
}

export function _tokenFromBackendToFrontend(token: BackendTokenType, productFields: ProductFieldType[]) {
  if (isBackendProductPathToken(token) && token.path) {
    let displayProductField = productFields.filter(field => field?.path?.toLowerCase() === token.path.toLowerCase())
    let type: string | string[] = 'Unknown'
    let display = 'Unknown'
    if (displayProductField.length > 0) {
      display = displayProductField[0].name || 'Unknown'
      type = displayProductField[0].type || 'Unknown'
    }
    if (token.path.startsWith('orderconfirmation')) {
      const match = /^orderconfirmation.([^.]+)/.exec(token.path);
      if (match) {
        display += ': ' + capitalize(match[1]);
      }
    }

    const finalToken: ProductPathTokenType = {
      path: token.path,
      display: display,
      rules: token.rules ? token.rules.map(rule => _sanitizeRuleForFrontend(rule)) : [],
      glossary: _transformGlossaryForFrontend(token.glossary),
      brandRules: token.brand_rules ? token.brand_rules.map(item => {
        return {
          brand: item.brand,
          rules: item.rules.map(r => _sanitizeRuleForFrontend(r)),
          exceptions: item.exceptions ? item.exceptions : []
        }
      }) : [],
      uv_representation: token.uv_representation
    }
    if (hasType(type, 'number')) {
      finalToken.measurement = token.measurement ? token.measurement : null;
    }
    if(token.path.startsWith("_images")) {
      finalToken.image_index = token?.image_index || 1;
    }
    return finalToken;
  }
  return token as TokenType;
}

export function _sanitizeSingleFieldConfigForFrontend(backendConfigElement: BackendRowConfigType, productFields: ProductFieldType[]) {
  let frontendConfig = {
    ...backendConfigElement,
    tokens: (backendConfigElement.tokens || []).map(token => {
      return _tokenFromBackendToFrontend(token, productFields)
    }),
    required: backendConfigElement.hasOwnProperty('required') ? backendConfigElement.required : false
  }

  let tokenIndexesReferencesAdjustments: {[key: string]: number} = {};
  frontendConfig.tokens.map((t, i) => {
    tokenIndexesReferencesAdjustments[i] = i
    return noop();
  })

  return adjustTokenIndexReferences(frontendConfig, tokenIndexesReferencesAdjustments);
}

export function _sanitizeRuleForBackend(rule: {[key: string]: unknown}): GeneralTokenRule {
  //ToDo: check if this is still needed
  if (rule.hasOwnProperty('defaultValue')) {
    rule.default = rule.defaultValue;
    delete rule.defaultValue;
  }

  if (rule.hasOwnProperty('type') && isGenerateDescriptionTokenRuleType(rule as GeneralTokenRule)) {
    delete rule.type;
  }

  return rule as GeneralTokenRule;
}

export function _sanitizeGlossaryForBackend(glossary: TokenGlossaryType[]) {
  if (!Array.isArray(glossary) && typeof glossary === 'object') {
    return glossary;
  }
  if (glossary === undefined || glossary === null || glossary.length === 0) {
    return {}
  }
  let sanitized: {[key: string] : string} = {};
  for (let item of glossary) {
    if (item.raw && item.value) {
      sanitized[item.raw] = item.value;
    }
  }
  return sanitized;
}

function isValidFieldRule(rule: FieldRuleType) {
  return !FieldRules.isBadRule(rule)
}

export function sanitizeTokenForBackend(token: TokenType, productFields: ProductFieldType[]): BackendTokenType {
  if (isProductPathToken(token) && token.path) {  // product field token
    let tokenSanitizedPath = token.path.replace(' ', '-').toLowerCase()
    let foundField = productFields?.find(field => {
      // we have some older pseudo-tokens generated for order confirmations with spaces in their names
      // for eg. we used to have "Order Confirmation" as the default one, generating the
      // orderconfirmation.Order Confirmation.quantity keys, which broke the underlying jmespath generated expression
      // so we switched to normalizing them on the backend
      // But we still have some configs which still use the old token format.
      return field.path?.replace(' ', '-').toLowerCase() === tokenSanitizedPath;
    })

    const finalToken: BackendProductPathTokenType = {
      path: tokenSanitizedPath,
      rules: token.rules ? token.rules.map(t => _sanitizeRuleForBackend(t)) : [],
      glossary: _sanitizeGlossaryForBackend(token.glossary),
      brand_rules: token.brandRules ? token.brandRules.map(brandRule => {
        return {
          brand: brandRule.brand,
          rules: brandRule.rules.map(r => _sanitizeRuleForBackend(r)),
          exceptions: brandRule.exceptions || []
        }
      }) : [],
      uv_representation: token.uv_representation
    }
    if (foundField && hasType(foundField.type, 'number')) {
      finalToken.measurement = token.measurement ? token.measurement : null;
    }
    if(token.path.startsWith("_images")) {
      finalToken.image_index = token?.image_index || 1;
    }
    return finalToken;
  }

  return token as BackendTokenType;
}

export function _sanitizeConfigForBackend(config: RowConfigType, productFields: ProductFieldType[]) {
  let newConfig: BackendRowConfigType = cloneObject(config) as BackendRowConfigType;
  let tokenIndexReferenceAdjustments: {[key: number]: number} = {};
  if (!newConfig.hasOwnProperty('required')) {
    newConfig.required = false;
  }
  if (newConfig.rules) {
    newConfig.rules = newConfig.rules.filter(isValidFieldRule)
  } else {
    newConfig.rules = []
  }

  newConfig.tokens = config.tokens.map(token => {
    return sanitizeTokenForBackend(token, productFields)
  }).filter((token, i) => {
    if (!token) {
      return false;
    } else {
      tokenIndexReferenceAdjustments[i] = i;
    }
    return token;
  });
  return adjustTokenIndexReferences(newConfig, tokenIndexReferenceAdjustments);
}


function sortFieldsBySpecifiedOrdering(data: DDTConfigType, ordering: string[]) {
  data.sort((a, b) => {
    const aKey = a.outputField;
    const bKey = b.outputField;
    if (ordering.includes(aKey) && !ordering.includes(bKey)) {
      return -1;
    }
    if (ordering.includes(bKey) && !ordering.includes(aKey)) {
      return 1;
    }
    return ordering.indexOf(aKey) - ordering.indexOf(bKey);
  })
}

export function fromBackendToFrontend(ddtSchema: DdtSchemaType,
                                      backendConfig: { [k: string]: BackendRowConfigType },
                                      productFields: ProductFieldType[],
                                      ordering: string[] | null = null
): DDTConfigType {
  if (backendConfig && (Object.keys(backendConfig).length > 0)) {
    let data: DDTConfigType = [];
    for (let k of Object.keys(backendConfig)) {
      data.push({
        outputField: k,
        config: _sanitizeSingleFieldConfigForFrontend(backendConfig[k], productFields)
      })
    }
    if (ordering && ordering.length) {
      sortFieldsBySpecifiedOrdering(data, ordering);
    }
    return data;
  }
  return buildEmptyConfigFromDdtSchema(ddtSchema);
}

type ConfigType = {
  outputField: string,
  config: RowConfigType
}[]

export function fromFrontendToBackend(config: ConfigType, productFields: ProductFieldType[]) {
  let backendObj: {[key: string]: BackendRowConfigType} = {};
  for (let configRow of config) {
    backendObj[configRow.outputField] = _sanitizeConfigForBackend(configRow.config, productFields);
  }
  return backendObj;
}
