import React, {useCallback, useEffect, useState} from "react";
import styles from './DDTMappingConfigFormV2.module.scss';
import ListGroup from "reactstrap/lib/ListGroup";
import ExampleDataContext, {ExampleFieldType} from "./example/contexts/ExampleDataContext";
import {adjustTokenIndexReferences} from "../backendAdapter";
import {ProductFieldsProvider} from "./utilities";
import {FieldRules} from "./fieldRulesUtils";
import {cloneObject} from "../../../objectUtils";
import TargetFieldEditor from "./components/TargetFieldEditor";
import {Input} from "reactstrap";
import ProductAndVariantsTabDisplay from "./ProductAndVariantsTabDisplay";
import ColumnsHeader from "./ColumnsHeader";
import CollapsedFieldRulesListItem from "./CollapsedFieldRulesListItem";
import ExamplePreviewBadge from "./example/ExamplePreviewBadge";
import ExpandedFieldRulesListItem from "./ExpandedFieldRulesListItem";
import {useFeatureFlag} from "../../../context/FeatureFlagsContext";
import addClassNames from "../../../classNameUtils";
import ActiveSheetsPills from "../single/modals/sheetSelection/ActiveSheetsPills";
import {
  DDTConfigType, FieldRuleType,
  isProductPathToken, isReplaceContentFieldRule,
  NewTokenType,
  ProductFieldType, ProductPathTokenType,
  RowConfigType, SeparatorTokenType, Suffix,
  TokenType
} from "./types";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";

type SelectedTokenType = {
  tokenIndex: number,
  outputField: string,
}

type OutputFieldConfigScreenProps = {
  config: DDTConfigType,
  onAddToken: (outputField: string, token: NewTokenType) => void,
  onRemoveToken: (outputField: string, index: number) => void,
  onTokenChange: (outputField: string, tokenIndex: number, tokenNewValue: SeparatorTokenType | ProductPathTokenType) => void,
  onFieldTokenClick: (outputField: string, tokenIndex: number) => void,
  onSuffixChange: (suffix: Suffix) => void,
  onAddFieldRule: (outputField: string, ruleId: string) => void,
  onChangeFieldRule: (outputField: string, index: number, newVal: FieldRuleType) => void,
  onRemoveFieldRule: (outputField: string, index: number) => void,
  name: string | null,
  onSave: () => void,
  onCancel: () => void,
  onBack?: () => void,
  productFieldsProvider: ProductFieldsProvider,
  activeTab: "Product" | "Variant",
  setActiveTab: (val: "Product" | "Variant") => void,
  isIncludeProductLineChecked: boolean,
  setIsIncludeProductLineChecked: (val: boolean) => void,
  isSkipFirstVariantChecked: boolean,
  setIsSkipFirstVariantChecked: (val: boolean) => void,
  isConfigureImageNaming: boolean,
  exampleProductData: { brandName: string } | null,
  scrollPosition: number,
  setScrollPosition: (val: number) => void,
  displayIndex: number,
  setDisplayIndex: (val: number) => void,
  fieldFilter: string,
  setFieldFilter: (val: string) => void,
  onConfigureDefaultCustomImageName: () => void,
  selectedSheetName: string | null,
  setSelectedSheetName?: (val: string | null) => void,
  onChangeRequired: (index: number) => void,
}

function OutputFieldConfigScreen({
                                   config, onAddToken, onRemoveToken, onTokenChange, onFieldTokenClick, onSuffixChange,
                                   onAddFieldRule, onChangeFieldRule, onRemoveFieldRule, name, onSave, onCancel,
                                   productFieldsProvider, activeTab, setActiveTab, onBack,
                                   setIsIncludeProductLineChecked, setIsSkipFirstVariantChecked,
                                   isIncludeProductLineChecked, isSkipFirstVariantChecked, isConfigureImageNaming,
                                   exampleProductData, setScrollPosition, scrollPosition, displayIndex, setDisplayIndex,
                                   fieldFilter, setFieldFilter, onConfigureDefaultCustomImageName,
                                   selectedSheetName, setSelectedSheetName, onChangeRequired
                                 }: OutputFieldConfigScreenProps) {


  const [exampleField, setExampleField] = useState<string | null>(displayIndex > -1 ? config[displayIndex].outputField : null)
  const outputPageRedesignFeature = useFeatureFlag('output.image_naming_modal');

  const listItemClasses = addClassNames([
    {className: styles.listGroup, condition: true},
    {className: "overflow-visible", condition: isConfigureImageNaming},
    {className: "overflow-auto", condition: !isConfigureImageNaming},
  ])

  useEffect(() => {
    if (isConfigureImageNaming && outputPageRedesignFeature.active) {
      if (config[0]?.config?.tokens?.length === 0) {
        onConfigureDefaultCustomImageName()
      }
    }
  }, [])

  useEffect(() => {
    let filteredConfigs = config.filter(c => {
      if (!fieldFilter) {
        return true;
      }
      return (c.outputField.toLowerCase().includes(fieldFilter.toLowerCase()))
    })

    if (filteredConfigs.length && displayIndex > -1) {
      if (displayIndex < filteredConfigs.length) {
        setExampleField(filteredConfigs[displayIndex].outputField)
      } else {
        setExampleField(null);
      }
    } else {
      setExampleField(null)
    }
  }, [config, displayIndex, fieldFilter])

  useEffect(() => {
    if(document.getElementById('list-group')?.scrollTop) {
      document.getElementById('list-group')!.scrollTop = scrollPosition
    }
  }, [scrollPosition])

  return <div className={styles.outputFieldConfigScreen}>
    <ColumnsHeader
      name={name}
      onSave={onSave}
      onCancel={onCancel}
      onBack={onBack}
      isConfigureImageNaming={isConfigureImageNaming}>
      {setSelectedSheetName && <ActiveSheetsPills selectedSheetName={selectedSheetName} setSelectedSheetName={setSelectedSheetName}/>}
      {!isConfigureImageNaming &&
        <ProductAndVariantsTabDisplay isIncludeProductLineChecked={isIncludeProductLineChecked}
                                      isSkipFirstVariantChecked={isSkipFirstVariantChecked}
                                      activeTab={activeTab}
                                      setActiveTab={setActiveTab}
                                      setIsIncludeProductLineChecked={setIsIncludeProductLineChecked}
                                      setIsSkipFirstVariantChecked={setIsSkipFirstVariantChecked}/>}
    </ColumnsHeader>
    <div style={{marginRight: "-1.25rem", marginLeft: "-1.25rem"}}>
      <ExamplePreviewBadge
        index={displayIndex + 1}
        page={exampleField}
        isConfigureImageNaming={isConfigureImageNaming}
        field={isConfigureImageNaming ? config[0].outputField : exampleField}
        productData={exampleProductData}/>
    </div>
    {!isConfigureImageNaming && <Input className={'mt-3'}
                                       placeholder={'Filter fields'}
                                       value={fieldFilter}
                                       onChange={e => setFieldFilter(e.target.value)}/>}
    <ListGroup id={'list-group'} className={listItemClasses}>
      {config.filter(c => {
        if (!fieldFilter) {
          return true;
        }
        return (c.outputField.toLowerCase().includes(fieldFilter.toLowerCase()))
      }).map((row, i) => {
        let outputField = row.outputField;
        let rowConfig = row.config;
        let isExpanded = displayIndex === i;

        return <CollapsedFieldRulesListItem key={i}
                                            isConfigureImageNaming={isConfigureImageNaming}
                                            rowConfig={rowConfig}
                                            onChangeRequired={() => onChangeRequired(i)}
                                            isExpanded={isExpanded}
                                            outputField={outputField}
                                            index={i}
                                            setDisplayIndex={setDisplayIndex}>
          {(isExpanded || isConfigureImageNaming) && <ExpandedFieldRulesListItem key={i}
                                                                                 isConfigureImageNaming={isConfigureImageNaming}
                                                                                 onChangeFieldRule={onChangeFieldRule}
                                                                                 onRemoveFieldRule={onRemoveFieldRule}
                                                                                 onFieldTokenClick={onFieldTokenClick}
                                                                                 rowConfig={rowConfig}
                                                                                 onAddFieldRule={onAddFieldRule}
                                                                                 onAddToken={onAddToken}
                                                                                 onSuffixChange={onSuffixChange}
                                                                                 onTokenChange={onTokenChange}
                                                                                 productFieldsProvider={productFieldsProvider}
                                                                                 onRemoveToken={onRemoveToken}
                                                                                 outputField={outputField}
                                                                                 setScrollPosition={setScrollPosition}/>}
        </CollapsedFieldRulesListItem>
      })}
    </ListGroup>
  </div>
}

function alterConfig(config: DDTConfigType, outputField: string, configMutator: (fieldConfig: RowConfigType) => void) {
  const newConfig = cloneObject(config);

  newConfig.forEach(item => {
    if (item.outputField === outputField) {
      configMutator(item.config);
    }
  })
  return newConfig;
}

function shiftMatchOnTokenIndexes(newConfig: DDTConfigType, outputField: string, index: number) {
  const modifiedConfig: DDTConfigType = newConfig.map(targetConfig => {
    if (targetConfig.outputField === outputField) {
      let sanitizedRules: FieldRuleType[] = []
      if (targetConfig.config.rules) {
        sanitizedRules = targetConfig.config.rules.map(rule => {
            let sanitizedConditions = isReplaceContentFieldRule(rule) ? (rule.conditions?.filter(
              condition => condition.match_on_token !== index
            ).map(
              condition => {
                if (condition.match_on_token && condition.match_on_token > index) {
                  return {...condition, match_on_token: condition.match_on_token - 2}
                } else {
                  return condition
                }
              }
            ) || []) : []
            return {...rule, conditions: sanitizedConditions}
          }
        )
      }
      const sanitizedConfig: RowConfigType = {...targetConfig.config, rules: sanitizedRules}
      return {...targetConfig, config: sanitizedConfig}
    } else {
      return targetConfig
    }
  })

  return modifiedConfig;
}

type ProductType = {
  id: string | null,
  name: string | null,
  collection: string | null,
  brand: string | null,
  styleNumber: string | null
}

function filterProducts(products: ProductType[]) {
  return products.filter((p): p is {
    id: string,
    name: string,
    collection: string | null,
    brand: string | null,
    styleNumber: string | null
  } => !!(p.id && p.name))
}

type DDTMappingConfigFormV2Props = {
  config: DDTConfigType,
  productExample: ExampleFieldType | null,
  tokenExample: string | null,
  onTargetTokenSelected: (val: SelectedTokenType) => void,
  candidateProductFields: ProductFieldType[],
  activeTab: "Product" | "Variant",
  setActiveTab: (val: "Product" | "Variant") => void,
  name: string | null,
  isIncludeProductLineChecked: boolean,
  setIsIncludeProductLineChecked: (val: boolean) => void,
  isSkipFirstVariantChecked: boolean,
  setIsSkipFirstVariantChecked: (val: boolean) => void,
  isRetailer: boolean,
  userBrand: string | null,
  userBrandName: string | null,
  brands: { id: string, name: string }[],
  onBack?: () => void,
  initialOpenTargetFieldToken?: any,
  onChange: (val: DDTConfigType) => void,
  onSave: (keepOpen?: boolean) => void,
  onCancel: () => void,
  isConfigureImageNaming: boolean,
  exampleProductData: {brandName: string} | null,
  products: ProductType[],
  onExampleProductSelect: (id: string) => void,
  refetchExampleProducts: (searchQuery: string, callback: () => void) => void,
  environment: RelayModernEnvironment,
  selectedSheetName: string | null,
  setSelectedSheetName?: (val: string | null) => void,
}

function DDTMappingConfigFormV2({
                                  config, productExample, tokenExample, onTargetTokenSelected,
                                  candidateProductFields, activeTab, setActiveTab, name,
                                  setIsIncludeProductLineChecked, isIncludeProductLineChecked,
                                  isSkipFirstVariantChecked, setIsSkipFirstVariantChecked,
                                  isRetailer, userBrand, userBrandName, brands, onBack,
                                  initialOpenTargetFieldToken = null,
                                  onChange, onSave, onCancel, isConfigureImageNaming,
                                  exampleProductData, products, onExampleProductSelect, refetchExampleProducts,
                                  environment, selectedSheetName, setSelectedSheetName
                                }: DDTMappingConfigFormV2Props) {
  const [targetFieldToken, _setTargetFieldToken] = useState(initialOpenTargetFieldToken);
  const [scrollPosition, setScrollPosition] = useState(0)
  const [displayIndex, setDisplayIndex] = useState(-1)
  const [fieldFilter, setFieldFilter] = useState('');

  useEffect(() => {
    setDisplayIndex(-1)
  }, [fieldFilter])

  const setTargetFieldToken = useCallback((val) => {
    onTargetTokenSelected(val);
    _setTargetFieldToken(val);
  }, [_setTargetFieldToken, onTargetTokenSelected]);

  const [productFieldsProvider, setProductFieldsProvider] = useState(new ProductFieldsProvider(candidateProductFields));
  let tokenConfig: TokenType | null = null;
  let targetProductField: ProductFieldType | null = null;
  let fieldConfig: RowConfigType | null = null;

  useEffect(() => {
    setProductFieldsProvider(new ProductFieldsProvider(candidateProductFields))
  }, [candidateProductFields])

  if (targetFieldToken) {
    fieldConfig = config.filter(item => {
      return item.outputField === targetFieldToken.outputField;
    })[0].config;
    tokenConfig = fieldConfig.tokens[targetFieldToken.tokenIndex];

    if (isProductPathToken(tokenConfig)) {
      targetProductField = productFieldsProvider.getByPath(tokenConfig.path);
    }
  }

  function getExampleContext() {
    return {
      productExample: productExample || {},
      tokenExample,
      products: filterProducts(cloneObject(products)),
      onExampleProductSelect,
      refetchExampleProducts,
    }
  }

  return <ExampleDataContext.Provider value={getExampleContext()}>
    {targetFieldToken && <TargetFieldEditor
      rowIndex={displayIndex + 1}
      sourceRowDisplay={targetFieldToken.outputField}
      fieldDisplay={targetProductField && targetProductField.name}
      fieldType={targetProductField && targetProductField.type}
      brands={brands}
      userBrand={userBrand}
      userBrandName={userBrandName}
      isRetailer={isRetailer}
      exampleProductData={exampleProductData}
      onBack={() => {
        setTargetFieldToken(null)
      }}
      value={tokenConfig}
      onChange={(newVal: TokenType) => {
        let newConfig = cloneObject(config);
        newConfig.map(item => {
          if (item.outputField === targetFieldToken.outputField) {
            item.config.tokens[targetFieldToken.tokenIndex] = newVal;
          }
          return null;
        })
        onChange(newConfig);
      }}
      productFieldsProvider={productFieldsProvider}
      environment={environment}
    />}
    {!targetFieldToken && <OutputFieldConfigScreen
      onBack={onBack}
      isConfigureImageNaming={isConfigureImageNaming}
      fieldFilter={fieldFilter}
      setFieldFilter={setFieldFilter}
      setIsIncludeProductLineChecked={setIsIncludeProductLineChecked}
      isIncludeProductLineChecked={isIncludeProductLineChecked}
      isSkipFirstVariantChecked={isSkipFirstVariantChecked}
      setIsSkipFirstVariantChecked={setIsSkipFirstVariantChecked}
      activeTab={activeTab}
      name={name}
      onSave={() => {
        setTargetFieldToken(null);
        onSave(true)
      }}
      onCancel={() => {
        setTargetFieldToken(null);
        onCancel()
      }}
      setActiveTab={setActiveTab}
      setScrollPosition={setScrollPosition}
      scrollPosition={scrollPosition}
      displayIndex={displayIndex}
      setDisplayIndex={setDisplayIndex}
      config={config}
      exampleProductData={exampleProductData}
      onAddToken={(outputField, token) => {
        let newConfig = cloneObject(config);
        newConfig.map(item => {
          if (item.outputField === outputField) {
            if (item.config.tokens.length === 0) {
              item.config.tokens.push({
                path: token.path,
                display: token.name,
                type: token.type,
                rules: token.rules || [],
                brandRules: [],
                glossary: []
              })
            } else {
              item.config.tokens.push({string: '-'})
              item.config.tokens.push({
                path: token.path,
                display: token.name,
                type: token.type,
                rules: token.rules || [],
                brandRules: [],
                glossary: []
              })
            }
          }
          return null;
        });
        onChange(newConfig);
      }}
      onConfigureDefaultCustomImageName={() => {
        let newConfig = cloneObject(config);
        newConfig.map(item => {
          if (isConfigureImageNaming) {
            item.config.tokens.push({
              path: "colors[0].sizes[0].gtin",
              display: candidateProductFields.filter(config => config.path === "colors[0].sizes[0].gtin")[0].name,
              rules: [],
              brandRules: [],
              glossary: []
            })
            item.config.suffix = {
              "value_start": "1",
              "only_for_same_value": true,
              "skip_first": false,
              "separator": "-"
            }
          }
          return null;
        });
        onChange(newConfig);
      }}
      onRemoveToken={(outputField, index) => {
        let newConfig = cloneObject(config)
        let tokenShiftStrategy: {[p: number]: (number | null)} = {};
        let changedOutputFieldIndex = null;

        newConfig.map((item, configIndex) => {
          if (item.outputField === outputField) {
            changedOutputFieldIndex = configIndex;
            if (item.config.tokens.length === 1) {
              item.config.tokens = [];
              tokenShiftStrategy[0] = null;
            } else if (item.config.tokens.length > 1 && index === 0) {
              // compute the adjustments before removing the tokens
              // because we need to reference the old indexes
              tokenShiftStrategy[0] = null;
              tokenShiftStrategy[1] = null;  // although these indexes shouldn't be referenced

              // for all indexes after the deleted one + the separator
              for (let i = 2; i < item.config.tokens.length; i++) {
                tokenShiftStrategy[i] = i - 2;
              }
              // first element is removed, remove the separator after it
              item.config.tokens.splice(index, 2);
            } else {
              // compute the adjustments before removing the tokens
              // because we need to reference the old indexes
              // remove the separator and "+" from before the token
              tokenShiftStrategy[index - 1] = null;  // this is the separator
              tokenShiftStrategy[index] = null;  // this is the removed token

              for (let i = index + 1; i < item.config.tokens.length; i++) {
                tokenShiftStrategy[i] = i - 2;
              }
              item.config.tokens.splice(index - 1, 2);
            }
          }
          if (item.config.tokens.length === 0) {
            // remove all rules when we remove the last token
            item.config.rules = [];
          }
          return null;
        })
        newConfig = shiftMatchOnTokenIndexes(newConfig, outputField, index)
        if (changedOutputFieldIndex !== null) {
          newConfig[changedOutputFieldIndex].config = adjustTokenIndexReferences(
            newConfig[changedOutputFieldIndex].config,
            tokenShiftStrategy
          );
          // change only if it changed, so we avoid useless re-renders.
          // This will cause a re-render no matter what, because newConfig is a distinct object
          onChange(newConfig);
        }
        return null;
      }}
      onFieldTokenClick={(outputField, tokenIndex) => {
        setTargetFieldToken({outputField, tokenIndex});
      }}
      onTokenChange={(outputField, tokenIndex, tokenNewValue) => {
        let newConfig = cloneObject(config)
        newConfig.map(item => {
          if (item.outputField === outputField) {
            item.config.tokens = item.config.tokens.map((token, i) => {
              if (i === tokenIndex) {
                return tokenNewValue;
              }
              return token;
            })
          }
          return null;
        })
        onChange(newConfig);
      }}
      onSuffixChange={(newSuffix) => {
        let newConfig = cloneObject(config)
        newConfig[0].config.suffix = newSuffix
        onChange(newConfig)
      }}
      onAddFieldRule={(outputField, ruleId) => {
        onChange(alterConfig(
          config,
          outputField,
          fieldConfig => {
            if (!fieldConfig.rules) {
              fieldConfig.rules = [];
            }

            const initialRuleState = FieldRules.getInitialRuleState(ruleId)
            if (initialRuleState) {
            fieldConfig.rules.push(initialRuleState)
            }
          }
        ))
      }}
      onChangeFieldRule={(outputField, index, newValue) => {
        onChange(alterConfig(
          config,
          outputField,
          config => {
            config.rules[index] = {...config.rules[index], ...newValue}
          }
        ))
      }}
      onRemoveFieldRule={(outputField, index) => {
        onChange(alterConfig(
          config,
          outputField,
          config => {
            config.rules = config.rules.filter((x, i) => i !== index);
          }
        ))
      }}
      productFieldsProvider={productFieldsProvider}
      selectedSheetName={selectedSheetName}
      setSelectedSheetName={setSelectedSheetName}
      onChangeRequired={(index) => {
        let newConfig = cloneObject(config)
        newConfig[index].config.required = !newConfig[index].config.required;
        onChange(newConfig)
      }}
    />}
  </ExampleDataContext.Provider>
}


export default DDTMappingConfigFormV2;
