import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  fromBackendToFrontend,
  fromFrontendToBackend,
  sanitizeTokenForBackend
} from "../../backendAdapter";
import {cloneObject} from "../../../../objectUtils";
import DDTMappingConfigFormV2 from "../../DDTMappingConfigV2Form/DDTMappingConfigFormV2";
import {createRefetchContainer, graphql} from 'react-relay';
import SimulateOutputTokenConfigForProductMutation
  from "../../../../mutations/output/SimulateOutputTokenConfigForProductMutation";
import SimulateOutputConfigForProductMutation
  from "../../../../mutations/output/SimulateOutputConfigForProductMutation";
import UpdateDdtMappingConfigMutation from "../../../../mutations/output/UpdateDdtMappingConfigMutation";
import UpdateDdtImageNamingFormatMutation from "../../../../mutations/output/UpdateDdtImageNamingFormatMutation";
import {getEmptyConfigClone} from "../../DDTMappingConfigV2Form/utilities";
import {PRODUCT} from "../../DDTMappingConfigV2Form/ProductAndVariantsTabDisplay";
import {
  DDTMappingConfigFormContextProvider,
  UniqueValueType
} from "../../DDTMappingConfigV2Form/DDTMappingConfigFormContext";
import {ourToast} from "../../../../atoms/Toast";
import {useSheetSelection} from "./sheetSelection/SheetSelectionContext";
import {CurrentUser, isBrand} from "../../../../context/CurrentUserContext";
import {SheetType} from "./sheetSelection/types";
import {ModalContent_ddt, ModalContent_ddt$data} from "./__generated__/ModalContent_ddt.graphql";
import {
  ModalContent_exampleProducts,
  ModalContent_exampleProducts$data
} from "./__generated__/ModalContent_exampleProducts.graphql";
import {
  ModalContent_productTemplates$data
} from "./__generated__/ModalContent_productTemplates.graphql";
import {
  DDTConfigType,
  DdtSchemaType,
  ImageDimensionsType,
  MeasurementDataType,
  ProductFieldType, RowConfigType
} from "../../DDTMappingConfigV2Form/types";
import {BackendRowConfigType} from "../../backendAdapterTypes";
import {RelayRefetchProp} from "react-relay/ReactRelayTypes";
import {ExampleFieldType} from "../../DDTMappingConfigV2Form/example/contexts/ExampleDataContext";

type ConfigType = { [k: string]: RowConfigType }
type BackendConfigType = { [k: string]: BackendRowConfigType }

type SelectedTokenType = {
  tokenIndex: number,
  outputField: string,
}

type BackendSchemaFields = ReadonlyArray<{
  readonly path: string | null;
  readonly name: string | null;
  readonly type: ReadonlyArray<string | null> | null;
  readonly category: string | null;
} | null> | null | undefined;

function extractConfig(ddt: ModalContent_ddt | null, config: BackendConfigType, isConfigureImageNaming: boolean): BackendConfigType {
  if (!isConfigureImageNaming) {
    return config
  }

  const imageNameFormat = (ddt?.imagesNamingFormat as BackendRowConfigType) || null;

  let finalConfig: BackendRowConfigType;
  if (!imageNameFormat || Object.keys(imageNameFormat).length === 0) {
    finalConfig = {
      required: false,
      tokens: [],
      rules: []
    }
  } else {
    finalConfig = imageNameFormat;
  }

  return {'Image name': finalConfig};
}

function getOnlyRelevantConfigPart(config: DDTConfigType, isConfigureImageNaming: boolean) {
  if (isConfigureImageNaming) {
    return config.filter(c => c.outputField === 'Image name')
  } else {
    return config
  }
}

export function adaptOrderingWithSheetName(ordering: string[], sheetName: string | null) {
  if (!sheetName) {
    return ordering
  }

  return ordering.filter(f => f.startsWith(sheetName + ' <-> ')).map(f => f.replace(sheetName + ' <-> ', ''))
}

function hasConfigBeforeVariant(checkedConfigBeforeVariant: BackendConfigType | ConfigType) {
  return Object.keys(checkedConfigBeforeVariant).length > 0;
}

function getProductsFromExampleProducts(exampleProducts: ModalContent_exampleProducts | null) {
  if (!exampleProducts?.productsExampleCandidates) {
    return []
  } else {
    return exampleProducts.productsExampleCandidates?.edges.map((e) => {
      return {
        id: e?.node?.id || null,
        name: e?.node?.name || null,
        collection: e?.node?.collectionName || null,
        brand: e?.node?.brandName || null,
        styleNumber: e?.node?.styleNumber || null
      }
    }).filter(x => x)
  }
}

function getProductFieldsFromSchemaFields (schemaFields: BackendSchemaFields, schemaFieldsForImage: BackendSchemaFields): ProductFieldType[] {
  if (!schemaFields && !schemaFieldsForImage) {
    return [];
  }

  return (schemaFields || schemaFieldsForImage || [])
  .filter((pf): pf is ProductFieldType => !!pf?.name && !!pf?.path)
  .map(pf => ({
    category: pf?.category || null,
    name: pf.name,
    path: pf.path,
    type: pf?.type ? [...pf.type.filter(t => !!t) as string[]] : null,
  }));
}

type ModalContentProps = {
  ddt: ModalContent_ddt$data | null,
  exampleProducts: ModalContent_exampleProducts$data | null,
  user: CurrentUser,
  brands: {id: string, name: string}[],
  isRetailer: boolean,
  allMeasurements?: MeasurementDataType[],
  selectedSheetName?: string | null,
  setSelectedSheetName?: (val: string | null) => void,
  productTemplates: ModalContent_productTemplates$data | null,
  relay: RelayRefetchProp,
  onSave?: (sheets: SheetType[]) => void,
  onBack?: () => void,
  onCancel: () => void,
  imageDimensions?: ImageDimensionsType | null,
  isConfigureImageNaming?: boolean,
}

function ModalContent({
                        ddt,
                        exampleProducts,
                        user,
                        brands,
                        isRetailer,
                        allMeasurements,
                        selectedSheetName = null,
                        setSelectedSheetName,
                        productTemplates = null,
                        relay,
                        onSave,
                        onBack,
                        onCancel,
                        imageDimensions = null,
                        isConfigureImageNaming = false,
                      }: ModalContentProps) {
  const configChangeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const mappingConfig = selectedSheetName
    ? ddt?.distributedatamappingconfigSet.edges.filter(e => e?.node?.sheetName === selectedSheetName)[0]?.node
    : ddt?.distributedatamappingconfigSet.edges[0]?.node;
  const checkedConfigBeforeVariant: BackendConfigType = typeof mappingConfig?.configBeforeVariant === "string" ?
    JSON.parse(mappingConfig.configBeforeVariant) :
    mappingConfig?.configBeforeVariant
  const productFields: ProductFieldType[] = getProductFieldsFromSchemaFields(productTemplates?.edges[0]?.node?.schemaFields, ddt?.schemaFieldsForImage);

  const products = useMemo(() => getProductsFromExampleProducts(exampleProducts), [exampleProducts])
  const [activeTab, setActiveTab] = useState<"Product" | "Variant">(PRODUCT)
  const [isIncludeProductLineChecked, setIsIncludeProductLineChecked] = useState(
    hasConfigBeforeVariant(checkedConfigBeforeVariant)
  )
  const [isSkipFirstVariantChecked, setIsSkipFirstVariantChecked] = useState(
    !!mappingConfig?.skipFirstVariant
  )

  const [config, setConfig] = useState(fromBackendToFrontend(
    ddt?.schema as DdtSchemaType,
    extractConfig(ddt, mappingConfig?.config as BackendConfigType, isConfigureImageNaming),
    productFields,
    isConfigureImageNaming ? ['Image name'] : adaptOrderingWithSheetName([...(ddt?.ordering || [])], selectedSheetName),
  ));

  const [configBeforeVariant, setConfigBeforeVariant] = useState(
    !hasConfigBeforeVariant(checkedConfigBeforeVariant) ?
      getEmptyConfigClone(config) :
      fromBackendToFrontend(
        ddt?.schema as DdtSchemaType,
        extractConfig(ddt, checkedConfigBeforeVariant, isConfigureImageNaming),
        productFields,
        isConfigureImageNaming ? ['Image name'] : adaptOrderingWithSheetName([...(ddt?.ordering || [])], selectedSheetName),
      )
  );

  const [exampleProductID, setExampleProductID] = useState(
    products && products[0] ? products[0].id : "0"
  );

  const [computedProductExample, setComputedProductExample] = useState<ExampleFieldType | null>(null);
  const [tokenExample, setTokenExample] = useState<string | null>(null);
  const [selectedToken, setSelectedToken] = useState<SelectedTokenType | null>(null);
  const {sheets, updateConfigForSheet, updateProductRowConfigForSheet, updateSkipFirstVariant} = useSheetSelection();

  const currentConfig = activeTab === PRODUCT ? config : configBeforeVariant
  const currentProduct = products.filter(pr => pr.id === exampleProductID)
  const selectedExampleProduct = (currentProduct.length > 0 && currentProduct[0].brand) ? {
    brandName: currentProduct[0].brand
  } : null;

  const onCheckboxClick = () => {
    if (isIncludeProductLineChecked) {
      let confirmation = window.prompt(
        "Are you sure you want to delete the Variants configuration? " +
        "Write DELETE if you wish to permanently delete it."
      )

      if (confirmation === "DELETE") {
        if (sheets.length && selectedSheetName) {
          updateProductRowConfigForSheet(selectedSheetName, {})
          updateSkipFirstVariant(selectedSheetName, false);
        }
        setActiveTab(PRODUCT)
        setIsIncludeProductLineChecked(false)
        setIsSkipFirstVariantChecked(false)
        if (mappingConfig?.id) {
          UpdateDdtMappingConfigMutation(
            relay.environment,
            {
              id: mappingConfig.id,
              config: fromFrontendToBackend(config, productFields),
              configBeforeVariant: {},
              skipFirstVariant: false
            },
            () => {
              ourToast('success', 'Variant configuration successfully deleted');
            },
            (err) => {
              ourToast('error', 'Failed to delete the configuration: ' + err.message);
            }
          )
        }
      }
    } else {
      setIsIncludeProductLineChecked(true)
      if (sheets.length && selectedSheetName) {
        updateProductRowConfigForSheet(
          selectedSheetName,
          fromFrontendToBackend(configBeforeVariant, productFields)
        )
      }
    }
  }

  useEffect(() => {
    if (!selectedToken) {
      setTokenExample(null);
    }
  }, [selectedToken])

  useEffect(() => {
    if (configChangeTimeoutRef.current) {
      clearTimeout(configChangeTimeoutRef.current);
      configChangeTimeoutRef.current = null;
    }

    configChangeTimeoutRef.current = setTimeout(() => {
      if (products.filter(product => product.id === exampleProductID).length === 0) {
        return null;
      }

      if (selectedToken) {
        let tokenConfig = currentConfig.filter(c => {
          return c.outputField === selectedToken.outputField
        })[0].config.tokens[selectedToken.tokenIndex]

        if (ddt?.id && exampleProductID) {
          SimulateOutputTokenConfigForProductMutation(
            relay.environment,
            {
              ddt: ddt?.id,
              config: sanitizeTokenForBackend(cloneObject(tokenConfig), []),
              product: exampleProductID,
            },
            resp => {
              setTokenExample((resp?.simulateOutputTokenConfigForProduct?.result as string) || null)
            },
            err => {
              ourToast('error', 'Failed to compute the output example: ' + err.message);
            }
          )
        }
      } else {
        if (ddt?.id && exampleProductID) {
          SimulateOutputConfigForProductMutation(
            relay.environment,
            {
              ddt: ddt.id,
              product: exampleProductID,
              config: fromFrontendToBackend(
                cloneObject(getOnlyRelevantConfigPart(currentConfig, isConfigureImageNaming)),
                productFields
              ),
            },
            resp => {
              setComputedProductExample((resp?.simulateOutputConfigForProduct?.result as ExampleFieldType) || null)
            },
            err => {
              ourToast('error', 'Failed to compute the output examples: ' + err.message);
            }
          )
        }
      }
    }, 500);

    return () => {
      if (configChangeTimeoutRef.current) {
        clearTimeout(configChangeTimeoutRef.current)
      }
    }
  }, [currentConfig, exampleProductID, selectedToken, products, ddt?.id, isConfigureImageNaming])


  const refetchExampleProducts = useCallback((searchQuery: string, callback: () => void) => {
    relay.refetch({searchQuery, count: 6, ddt: ddt?.id, configureImageNaming: isConfigureImageNaming}, {}, callback);
  }, [ddt?.id, isConfigureImageNaming]);

  const usedUniqueValues: UniqueValueType[] = (mappingConfig?.usedUniqueValues?.edges.map(edge => {
    if (edge?.node?.value && edge?.node?.jsonSchemaValue) {
      return {
        value: edge.node.value,
        jsonSchemaValue: edge.node.jsonSchemaValue
      }
    }

    return null;
  }).filter((x): x is UniqueValueType => !!x)) || []

  return <DDTMappingConfigFormContextProvider usedUniqueValues={usedUniqueValues}
                                              environment={relay.environment}
                                              allMeasurements={allMeasurements}>
    <DDTMappingConfigFormV2
      onBack={onBack}
      name={ddt?.name || null}
      config={currentConfig}
      isConfigureImageNaming={isConfigureImageNaming}
      isIncludeProductLineChecked={isIncludeProductLineChecked}
      setIsIncludeProductLineChecked={onCheckboxClick}
      isSkipFirstVariantChecked={isSkipFirstVariantChecked}
      setIsSkipFirstVariantChecked={(value: boolean) => {
        setIsSkipFirstVariantChecked(value);
        if (sheets.length && selectedSheetName) {
          updateSkipFirstVariant(selectedSheetName, value && isIncludeProductLineChecked);
        }
      }}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      productExample={computedProductExample}
      tokenExample={tokenExample}
      onTargetTokenSelected={val => {
        setSelectedToken(val);
      }}
      onChange={value => {
        if (activeTab === PRODUCT) {
          setConfig(value);
          if (selectedSheetName) {
            updateConfigForSheet(selectedSheetName, fromFrontendToBackend(value, productFields));
          }
        } else {
          setConfigBeforeVariant(value);
          if (selectedSheetName) {
            updateProductRowConfigForSheet(selectedSheetName, fromFrontendToBackend(value, productFields));
          }
        }
      }
      }
      candidateProductFields={productFields}
      onCancel={onCancel}
      exampleProductData={selectedExampleProduct}
      products={products}
      onExampleProductSelect={(id: string) => {
        setExampleProductID(id);
      }}
      refetchExampleProducts={refetchExampleProducts}
      isRetailer={isRetailer}
      userBrand={isBrand(user) ? user.brand.id : null}
      userBrandName={isBrand(user) ? user.brand.name : null}
      brands={brands}
      environment={relay.environment}
      onSave={(keepOpen = false) => {
        if (isConfigureImageNaming) {
          if (ddt?.id) {
            UpdateDdtImageNamingFormatMutation(
              relay.environment,
              {
                ddtId: ddt.id,
                config: fromFrontendToBackend(getOnlyRelevantConfigPart(config, true), productFields)['Image name'],
                imageDimensions: imageDimensions
              },
              () => {
                if (keepOpen) {
                  ourToast('success', 'Saved successfully!')
                } else {
                  onCancel();
                }
              },
              err => {
                ourToast('error', 'Failed to save the configuration', err.message);
              }
            )
          }
        } else {
          // sheets is not populated if we only have a single mapping config available.
          // so we need to "simulate" it as a sheet when we save it.
          if (onSave) {
            if (!sheets.length) {
              onSave([{
                isActive: true,
                productSelectors: [],
                mappingConfigId: mappingConfig?.id || "",
                config: fromFrontendToBackend(config, productFields),
                configBeforeVariant: isIncludeProductLineChecked ? fromFrontendToBackend(configBeforeVariant, productFields) : {},
                skipFirstVariant: isSkipFirstVariantChecked,
                name: "",
                productTemplateId: "",
              }])
            } else {
              onSave(sheets);
            }
          }
        }
      }}
      selectedSheetName={selectedSheetName}
      setSelectedSheetName={setSelectedSheetName}
    />
  </DDTMappingConfigFormContextProvider>
}

export default createRefetchContainer(
  ModalContent,
  {
    ddt: graphql`
      fragment ModalContent_ddt on DistributeDataTemplateNode
      @argumentDefinitions(
        configureImageNaming: {type: "Boolean", defaultValue: false}
      ) {
        id
        name
        schema @skip(if: $configureImageNaming)
        ordering @skip(if: $configureImageNaming)
        imagesNamingFormat @include(if: $configureImageNaming)
        schemaFieldsForImage @include(if: $configureImageNaming) {
          name
          category
          path
          type
        }
        distributedatamappingconfigSet {
          edges {
            node {
              id
              isActive
              config @skip(if: $configureImageNaming)
              configBeforeVariant
              useSystem
              sheetName
              skipFirstVariant
              usedUniqueValues{
                edges {
                  node {
                    id
                    value
                    jsonSchemaValue
                  }
                }
              }
              productTemplate {
                id
              }
            }
          }
        }
      }
    `,
    exampleProducts: graphql`
      fragment ModalContent_exampleProducts on Query
      @argumentDefinitions(
        searchQuery: {type: "String", defaultValue: ""},
        count: {type: "Int", defaultValue: 1}
      ) {
        productsExampleCandidates(first: $count, search: $searchQuery) {
          edges {
            node {
              id
              name
              styleNumber
              brandName
              collectionName
            }
          }
        }
      }
    `,
    productTemplates: graphql`
      fragment ModalContent_productTemplates on ProductTemplateNodeConnection
      @argumentDefinitions(
        ddt: {type: "ID", defaultValue: null},
        configureImageNaming: {type: "Boolean", defaultValue: false}
      ) {
        edges {
          node {
            id
            name
            schemaFields(ddt: $ddt includeImageFields: true) @skip(if: $configureImageNaming) {
              path
              name
              type
              category
            }
          }
        }
      }
    `
  },
  graphql`
    query ModalContentExampleProductsRefetchQuery($searchQuery: String, $count: Int) {
      ...ModalContent_exampleProducts @arguments(searchQuery: $searchQuery, count: $count)
    }
  `
)
