import React, {DetailedHTMLProps, InputHTMLAttributes, SyntheticEvent, useEffect, useState} from "react";
import styles from './ModernFileInput.module.scss';
import defaultAddFilesIcon from './default-add-files-icon.svg';
import hoverAddFilesIcon from './hover-add-files-icon.svg';
import {Container, Col, Row} from "reactstrap";
import addClassNames from "../../classNameUtils";

type ModernFileInputProps = {
  value: File[],
  onChange: (files: File[]) => void,
  title?: string | null | JSX.Element
  subtitle?: string | null | JSX.Element
  image?: string | null,
  allowMultipleSelection?: boolean,
  filesComponent?: JSX.Element | JSX.Element[],
  onRemove: (f: File) => void,
  isForceHover?: boolean,
  dataTestId?: string,
  inputProps?: Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "multiple">
}


export default function ModernFileInput(props: ModernFileInputProps) {
  const {value, title, subtitle, image, allowMultipleSelection = false, filesComponent, onChange, onRemove, isForceHover, inputProps, dataTestId} = props;
  let [displayImage, setDisplayImage] = useState(image ? image : defaultAddFilesIcon)

  useEffect(() => {
      if (!image) {
        setDisplayImage(isForceHover ? hoverAddFilesIcon : defaultAddFilesIcon)
      }
    },
    [isForceHover]
  )

  const inputPropsRest = inputProps ? Object.fromEntries(Object.entries(inputProps).filter(([k, v]) => k !== 'className')) : undefined

  return <div className={styles.container}>
    <div className={styles.border}>
      <img className={styles.image} src={displayImage} alt=""/>
      {React.isValidElement(title) ?
        title :
        <p className={styles.title}>{title || 'Add files'}</p>}
      {React.isValidElement(subtitle) ?
        subtitle :
        <p className={styles.subtitle}>{subtitle || 'Drag and drop or click to add files.'}</p>}
      <input type="file"
             multiple={allowMultipleSelection}
             onChange={(ev: SyntheticEvent<HTMLInputElement>) => {
               let target = ev.target as HTMLInputElement;
               let values: File[] = [];
               if (target.files) {
                 values = [...target.files];
               }
               onChange(values);
             }}
             className={addClassNames([
               {className: styles.hiddenInput, condition: true},
               {className: inputProps?.className, condition: !!inputProps?.className}
             ])}
             data-testid={dataTestId}
             {...inputPropsRest}/>
      {filesComponent || <Container className={"mb-3 overflow-auto " + styles.scrollableContainer}>
        <Row>
          {value.map((file, i) => {
            return <Col key={i} md={4}>
            <span className={styles.uploadedFileIndicator}>
            <i className="fas fa-xmark-circle mr-2 text-primary" onClick={() => onRemove(file)}/>
              {file.name}
          </span>
            </Col>
          })}
        </Row>
      </Container>}
    </div>

  </div>
}
