import React from "react";
import Dropdown from "../../../../../../../dataInput/Dropdown";
import SecondaryActionButton from "../../../../../../../general/SecondaryActionButton";
import {RemoveSectionRuleType} from "../../../../types";



type RuleExtrasDropdownProps = {
  onChange: (value: RemoveSectionRuleType) => void,
  ruleData: RemoveSectionRuleType
}

export default function RuleExtrasDropdown({onChange, ruleData}: RuleExtrasDropdownProps) {
  return <Dropdown
    caret={false}
    className={"ml-3"}
    toggleButtonProps={{tag: SecondaryActionButton, size: 'sm'}}
    value={{label: <i className="fas fa-plus"/>, value: null}}
    options={[
      {value: 'add-exception', label: <>Add exception</>}
    ]}
    onChange={({value}) => {
      if (value === 'add-exception') {
        onChange({
          ...ruleData,
          exceptions: [
            ...(ruleData.exceptions || []),
            {field: null, op: 'eq', value: null}
          ]
        })
      }
    }}
  />
}
