import React, {useEffect, useState} from "react";
import styles from './TargetFieldEditor.module.scss';
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Rule from "./Rule";
import {cloneObject} from "../../../../objectUtils";
import FieldGlossaryEditor from "./FieldGlossaryEditor";
import AddRuleDropdown from "./dropdowns/AddRuleDropdown";
import {BrandRule} from "./token/BrandRule";
import RULES from "./rules";
import RuleMapper from "./RuleMapper";
import addClassNames from "../../../../classNameUtils";
import DataPointsHeader from "./DataPointsHeader";
import ExamplePreviewBadge from "../example/ExamplePreviewBadge";
import UniqueValueRepresentationsDropdown from "./token/UniqueValueRepresentationsDropdown";
import GenerateDescriptionFieldEditor from "./GenerateDescriptionFieldEditor";
import ImageIndexDropdown from "./token/ImageIndexDropdown";

function isInlineRule(rule) {
  return !isBlockRule(rule);
}

function isBlockRule(rule) {
  return RULES.getRuleInfo(rule.type).hasOwnProperty('blockRule');

}

function TokenRules({token, fieldDisplay, productFieldsProvider, onChange, fieldType, isRetailer}) {
  let [isExpanded, setIsExpanded] = useState(true)

  let caretClasses = addClassNames([
    {className: "fa-solid ml-auto", condition: true},
    {className: "fa-caret-down", condition: !isExpanded},
    {className: "fa-caret-up", condition: isExpanded},
  ])

  function createRuleElement(rule, index) {
    return <Rule
      fieldDisplay={fieldDisplay}
      {...rule}
      productFieldsProvider={productFieldsProvider}
      onChange={newRuleContent => {
        let newValue = cloneObject(token);
        newValue.rules[index] = newRuleContent;
        onChange(newValue);
      }}
      onRemove={() => {
        let newValue = cloneObject(token);
        newValue.rules.splice(index, 1);
        onChange(newValue);
      }}
    />
  }

  return <>
    <div className={styles.rules}>
      <div className={'d-flex align-items-center w-100'}>
        <span className={styles.generalRulesText}>
          General rules
        </span>
        {isExpanded ? <AddRuleDropdown
            tokenValueType={fieldType}
            onChange={onChange}
            token={token}
            allowBrandRules={isRetailer}
            tooltip={'Add General Rules'}
          /> :
          <span style={{marginLeft: "-0.5rem"}}>...</span>}
        <i className={caretClasses}
           style={{fontSize: "1rem", padding: '0.5rem', margin: '-0.5rem', cursor: 'pointer'}}
           onClick={() => setIsExpanded(!isExpanded)}/>
      </div>
      {isExpanded && token.rules?.length > 0 && <div className={"w-100"} style={{marginTop: "-0.5rem"}}>
        <hr style={{marginLeft: "-1.5rem", marginRight: "-1.5rem"}} className={"mt-0"}/>
        <RuleMapper rules={token.rules} createRuleElement={createRuleElement}/>
      </div>}
    </div>
  </>
}

export function validateRules(rules, setInvalidityMessage) {
  if (rules.filter(isInlineRule).filter(
    rule => rule.type === RULES.NUMERIC_FORMAT_RULE.id && rule.numeric_format === null
  ).length > 0) {
    setInvalidityMessage("It seems like you have an unselected numeric format rule. Select a format or remove it before going back")
  } else {
    setInvalidityMessage(null)
  }
}

function getUniqueValueType(type) {
  if (!type) {
    return null;
  }
  for (let t of type) {
    if (t.startsWith('$$')) {
      return t
    }
  }
  return null;
}

function TargetFieldEditor({
                             value, fieldDisplay, fieldType, rowIndex, sourceRowDisplay,
                             productFieldsProvider, brands = [],
                             isRetailer, userBrand, userBrandName,
                             onChange, onBack,
                             exampleProductData,
                             environment
                           }) {
  const [invalidityMessage, setInvalidityMessage] = useState(null)
  const isGeneratedDescription = value.path === '_gen_description'
  const isImage = value.path.startsWith("_images");

  const uniqueValueType = getUniqueValueType(fieldType);
  const representation = value.uv_representation || null;

  useEffect(() => {
    if (value.rules) {
      validateRules(value.rules, setInvalidityMessage)
    }
  }, [value])

  return <>
    <DataPointsHeader invalidityMessage={invalidityMessage} onBack={onBack}/>
    <ExamplePreviewBadge index={rowIndex}
                         sourceRowDisplay={sourceRowDisplay}
                         field={null}
                         page={fieldDisplay}
                         productData={exampleProductData}
                         showInfo={'brand'}/>

    {isGeneratedDescription ?
      <GenerateDescriptionFieldEditor value={value} onChange={onChange}/> :
      <>
        <Row className={styles.titleContainer}>
        <Col className={'p-0'}>
          <h5>{fieldDisplay}</h5>
        </Col>
        <Col className={'d-flex justify-content-end'} style={{position: 'relative'}}>
          {uniqueValueType && <UniqueValueRepresentationsDropdown
            type={uniqueValueType}
            environment={environment}
            representation={representation}
            setRepresentation={(r) => {
              onChange({...value, uv_representation: r})
            }}
          />}
          {isImage && <ImageIndexDropdown imageIndex={value?.image_index || 1} className={'ml-2'}
                                          setImageIndex={index => onChange({...value, image_index: index})} />}
        </Col>
      </Row>
    <div className={styles.body}>
      <TokenRules token={value}
                  onChange={onChange}
                  productFieldsProvider={productFieldsProvider}
                  fieldDisplay={fieldDisplay}
                  fieldType={fieldType}
                  isRetailer={isRetailer}
      />

      <div className={"mr-3"}>
        {value.brandRules && value.brandRules.map((brandRule, i) => {
          if (!isRetailer && (userBrand !== brandRule.brand)) {
            return null;
          }
          if (!isRetailer && brands.length === 0) {
            brands = [{id: brandRule.brand, name: userBrandName || 'Current brand'}]
          }
          return <BrandRule
            key={i}
            brands={brands}
            selectedBrand={brandRule.brand}
            currentFieldDisplay={fieldDisplay}
            productFieldsProvider={productFieldsProvider}
            disabled={(!isRetailer && userBrand !== null)}
            rules={brandRule.rules}
            exceptions={brandRule.exceptions}
            onChange={(newBrandRule) => {
              let newVal = cloneObject(value);
              newVal.brandRules[i] = newBrandRule;
              onChange(newVal);
            }}
            onRemove={() => {
              let newVal = cloneObject(value)
              newVal.brandRules = newVal.brandRules.filter((x, j) => i !== j);
              onChange(newVal);
            }}
          />
        })}
      </div>

      <div className={"mt-2 mr-3"}>
        <FieldGlossaryEditor type={fieldType}
                             isOrientation={value.path.split(".")[value.path.split(".").length - 1] === "orientation"}
                             glossaryValues={value.glossary}
                             onRemove={(index) => {
                               value.glossary.splice(index, 1)
                               onChange(value)
                             }}
                             onAddGlossaryValue={() => onChange(
                               {
                                 ...value,
                                 glossary: [...(value.glossary || []), {
                                   type: 'raw',
                                   raw: '',
                                   value: ''
                                 }]
                               })}
                             onGlossaryValuesChange={(index, newGlossaryValues) => {
                               onChange({
                                 ...value,
                                 glossary: newGlossaryValues
                               })
                             }}/>
      </div>
    </div>
    </>}
  </>
}

export default TargetFieldEditor;
