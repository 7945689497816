import React, {useState} from "react";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Dropdown from "../../../../../atoms/Dropdown";
import AddRuleDropdown from "../dropdowns/AddRuleDropdown";
import RULES from "../rules";
import Rule from "../Rule";
import RuleException from "./rules/commons/RuleException";
import styles from "../Rule.module.scss";
import addClassNames from "../../../../../classNameUtils";
import RuleMapper from "../RuleMapper";
import RemoveRuleIcon from "../fieldRules/RemoveRuleIcon";
import {ProductFieldsProvider} from "../../utilities";
import {GeneralRulesTypes, GeneralTokenRule, TokenRuleException} from "../../types";

type BrandType = {
  name: string,
  id: string
}

type BrandRuleProps = {
  selectedBrand: string,
  rules: GeneralTokenRule[],
  exceptions: TokenRuleException[],
  brands: BrandType[],
  disabled: boolean,
  productFieldsProvider: ProductFieldsProvider,
  currentFieldDisplay: string,
  onChange: (val: {brand: string, rules: GeneralTokenRule[], exceptions?: TokenRuleException[]}) => void,
  onRemove: () => void
}

export function BrandRule({
                            selectedBrand, rules, exceptions = [], brands, disabled,
                            productFieldsProvider, currentFieldDisplay,
                            onChange, onRemove
                          }: BrandRuleProps) {

  let [isExpanded, setIsExpanded] = useState(true)

  let brand: BrandType | null = brands.filter(b => b.id === selectedBrand)[0]
  if (!brand) {
    brand = null;
  }
  function createRuleElement(renderedRule: GeneralTokenRule, i: number) {
    const renderedRuleWithoutType: Omit<GeneralTokenRule, 'type'> & {type?: GeneralRulesTypes} = renderedRule;

    return <Rule
      isBrandRule={true}
      type={RULES.getRuleInfo(renderedRule.type).id}
      fieldDisplay={currentFieldDisplay}
      onChange={(newRuleVal: GeneralTokenRule) => {
        onChange({
          brand: brand?.id || '',
          rules: rules.map((origRule, j) => {
            return (j === i) ? newRuleVal : origRule;
          })
        })
      }}
      onRemove={() => {
        onChange({
          brand: brand?.id || '',
          rules: rules.filter((r, j) => (i !== j)),
          exceptions: exceptions
        })
      }}
      productFieldsProvider={productFieldsProvider}
      {...renderedRuleWithoutType}
    />
  }

  let caretClasses = addClassNames([
    {className: "fa-solid  ml-auto", condition: true},
    {className: "fa-caret-down", condition: rules.length !== 0 && !isExpanded},
    {className: "fa-caret-up", condition: rules.length !== 0 && isExpanded},
  ])

  return <>
    <div className={styles.brandRuleRow}>
      <Row>
        <Col className={'d-flex flex-row align-items-center'}>
          <div className={styles.brandRuleText}>
            Brand specific rules for
          </div>
          <div className={styles.brandDropdownContainer}>
            <Dropdown
              disabled={disabled}
              options={brands.map(b => ({value: b.id, label: b.name}))}
              currentValue={brand ? {value: brand.id, label: brand.name} : null}
              placeholder={'Select brand'}
              onSelectOption={({value}) => {
                onChange({brand: typeof(value) === 'string' ? value : '', rules: (rules || [])});
              }}
            />
          </div>
          {brand && <AddRuleDropdown
            tooltip={'Add more to this rule'}
            allowBrandRules={false}
            allowCustomizeValues={true}
            allowTextCaseRules={false}
            token={{rules: rules, path: null, display: null, brandRules: [], glossary: []}}
            onChange={({rules, exceptions}) => {
              onChange({brand: brand?.id || '', rules, exceptions});
            }}
          />}
          {rules.length === 0 ? (
            <RemoveRuleIcon
              className={'ml-auto'}
              onClick={onRemove}/>
          ) : (
            <i className={caretClasses}
               style={{fontSize: "1rem", cursor: 'pointer', padding: '0.5rem', margin: '-0.5rem'}}
               onClick={() => setIsExpanded(!isExpanded)}/>
          )}

        </Col>
      </Row>
      {isExpanded && rules.length > 0 && <>
        <hr className={styles.separator}/>
        <RuleMapper rules={rules} createRuleElement={createRuleElement}/>
      </>
      }
      <div className={styles.addRuleButtonContainer}>
      </div>
    </div>
    <div className={styles.exceptionsContainer}>
      {exceptions.map((exc, excIndex) => {
        return <RuleException key={excIndex}
                              value={exc.value}
                              field={exc.field}
                              op={exc.op}
                              productFieldsProvider={productFieldsProvider}
                              onChange={({field, value, op}: TokenRuleException) => {
                                onChange({
                                  brand: brand?.id || '',
                                  rules,
                                  exceptions: exceptions.map((origExc, j) => {
                                    if (excIndex === j) {
                                      return {field, value, op}
                                    }
                                    return origExc;
                                  })
                                })
                              }}
                              onRemove={() => {
                                onChange({
                                  brand: brand?.id || '',
                                  rules,
                                  exceptions: exceptions.filter((e, j) => excIndex !== j)
                                })
                              }}
        />
      })}
    </div>
  </>
}
