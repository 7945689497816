import React from "react";
import ReplaceTextOptions from "../commons/ReplaceTextOptions";
import Input from "../../../../../../../atoms/Input";
import RuleExtrasDropdown from "../commons/RuleExtrasDropdown";
import RemoveRuleIcon from "../../../fieldRules/RemoveRuleIcon";


export default function RemoveCharactersRule({pattern, where, onChange, exceptions, onRemove}) {
  return <div className={'d-flex mt-4 ml-4'}>
    <Input value={pattern}
           width={"10.5rem"}
           onChange={e => onChange({pattern: e.target.value})}
    />
    <ReplaceTextOptions onChange={onChange} where={where}/>
    <RuleExtrasDropdown onChange={onChange} ruleData={{pattern, exceptions}}/>
    <RemoveRuleIcon className={'ml-auto'} onClick={onRemove}/>
  </div>
}
