import React from "react";
import {Input, InputGroup, InputGroupAddon} from "reactstrap";
import styles from './InputWithIcon.module.scss';

export default function InputWithIcon({icon, value, onChange, ...props}) {
  let iconComponent;
  if (typeof icon === 'string') {
    iconComponent = <i className={`fas ${icon}`}/>
  } else {
    iconComponent = icon;
  }
  return <InputGroup>
    <InputGroupAddon addonType={'prepend'} className={styles.icon}>
      {iconComponent}
    </InputGroupAddon>
    <Input value={value} onChange={onChange} className={styles.input} {...props}/>
  </InputGroup>
}
