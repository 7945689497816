import styles from "./InfoRow.module.scss";
import {Col, Row} from "reactstrap";
import Tooltip from "../../../../../atoms/Tooltip";
import React from "react";


function CircleQuestionMarkIcon({className}: { className?: string }) {
  return <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0C3.5625 0 0 3.59375 0 8C0 12.4375 3.5625 16 8 16C12.4063 16 16 12.4375 16 8C16 3.59375 12.4062 0 8 0ZM8 15C4.125 15 1 11.875 1 8C1 4.15625 4.125 1 8 1C11.8438 1 15 4.15625 15 8C15 11.875 11.8438 15 8 15ZM7.5 10.75C7.0625 10.75 6.75 11.0937 6.75 11.5C6.75 11.9375 7.0625 12.25 7.5 12.25C7.90625 12.25 8.25 11.9375 8.25 11.5C8.25 11.0937 7.90625 10.75 7.5 10.75ZM8.90625 4H7.0625C5.90625 4 5 4.9375 5 6.09375V6.375C5 6.65625 5.21875 6.875 5.5 6.875C5.75 6.875 6 6.65625 6 6.375V6.09375C6 5.5 6.46875 5 7.0625 5L8.90625 5C9.5 5 10 5.5 10 6.09375C10 6.46875 9.78125 6.84375 9.4375 7.03125L7.5625 7.96875C7.21875 8.15625 7 8.53125 7 8.9375V9.5C7 9.78125 7.21875 10 7.5 10C7.75 10 8 9.78125 8 9.5V8.9375C8 8.90625 8 8.875 8.03125 8.84375L9.90625 7.90625C10.5625 7.53125 11 6.84375 11 6.09375C11 4.9375 10.0625 4 8.90625 4Z"
      fill="white"/>
  </svg>

}


export default function InfoRow() {
  return <Row className={`${styles.infoRow}`}>
        <Col className={'d-flex px-0'}>
          Enable sheets to set sheet-specific data requirements

          <Tooltip
            wrapperStyle={{width: '250px'}}
            mode={"light"}
            text={'Once a sheet is enabled, you can specify when sheet-specific data requirements can be applied.'}>
            <CircleQuestionMarkIcon className={'ml-2'}/>
          </Tooltip>
        </Col>
      </Row>
}
